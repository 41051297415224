/**
 * Flag utilities for handling country codes and language mappings
 */

// Language to country code mappings for special cases
const LANGUAGE_COUNTRY_MAPPINGS = {
  // English Variants
  "en-US": "US",
  "en-GB": "GB",
  "en-AU": "AU",
  "en-CA": "CA",
  "en-NZ": "NZ",
  "en-IE": "IE",
  "en-ZA": "ZA",

  // Spanish Variants
  "es-ES": "ES",
  "es-MX": "MX",
  "es-AR": "AR",
  "es-CO": "CO",
  "es-CL": "CL",
  "es-PE": "PE",
  "es-VE": "VE",
  "es-UY": "UY",
  "es-PY": "PY",
  "es-EC": "EC",
  "es-CR": "CR",
  "es-DO": "DO",
  "es-GT": "GT",
  "es-PA": "PA",
  "es-SV": "SV",
  "es-HN": "HN",
  "es-NI": "NI",
  "es-BO": "BO",
  "es-PR": "PR",

  // French Variants
  "fr-FR": "FR",
  "fr-CA": "CA",
  "fr-BE": "BE",
  "fr-CH": "CH",
  "fr-LU": "LU",
  "fr-MC": "MC",

  // German Variants
  "de-DE": "DE",
  "de-AT": "AT",
  "de-CH": "CH",

  // Chinese Variants
  "zh-CN": "CN",
  "zh-TW": "TW",
  "zh-HK": "HK",
  "zh-SG": "SG",
  "zh-MO": "MO",

  // Other Major Languages
  "hi-IN": "IN",
  "bn-IN": "IN",
  "ja-JP": "JP",
  "ko-KR": "KR",
  "ru-RU": "RU",
  "tr-TR": "TR",
  "it-IT": "IT",
  "nl-NL": "NL",
  "pl-PL": "PL",
  "vi-VN": "VN",
  "th-TH": "TH",
  "id-ID": "ID",
  "ms-MY": "MY",
  "fil-PH": "PH",

  // Additional Languages
  "sq-AL": "AL",
  "be-BY": "BY",
  "bs-BA": "BA",
  "ny-MW": "MW",
  "dv-MV": "MV",
  "dz-BT": "BT",
  "fo-FO": "FO",
  "ka-GE": "GE",
  "el-GR": "GR",
  "gu-IN": "IN",
  "he-IL": "IL",
  "is-IS": "IS",
  "kn-IN": "IN",
  "kk-KZ": "KZ",
  "km-KH": "KH",
  "ky-KG": "KG",
  "lo-LA": "LA",
  "lv-LV": "LV",
  "lt-LT": "LT",
  "mk-MK": "MK",
  "mg-MG": "MG",
  "ml-IN": "IN",
  "mt-MT": "MT",
  "mr-IN": "IN",
  "mn-MN": "MN",
  "my-MM": "MM",
  "ne-NP": "NP",
  "no-NO": "NO",
  "or-IN": "IN",
  "ps-AF": "AF",
  "fa-IR": "IR",
  "pa-IN": "IN",
  "ro-RO": "RO",
  "sr-RS": "RS",
  "si-LK": "LK",
  "sk-SK": "SK",
  "sl-SI": "SI",
  "so-SO": "SO",
  "sw-KE": "KE",
  "sv-SE": "SE",
  "tg-TJ": "TJ",
  "ta-IN": "IN",
  "te-IN": "IN",
  "bo-CN": "CN",
  "ti-ET": "ET",
  "tk-TM": "TM",
  "ur-PK": "PK",
  "ug-CN": "CN",
  "uz-UZ": "UZ",
};

/**
 * Converts a country code to a flag emoji
 * @param {string} countryCode - Two-letter country code (e.g., 'US', 'GB')
 * @returns {string} Flag emoji for the country
 */
export const getFlagEmoji = (countryCode) => {
  if (!countryCode) return "";

  try {
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  } catch (error) {
    console.warn(
      `Failed to generate flag emoji for country code: ${countryCode}`
    );
    return "";
  }
};

/**
 * Extracts country code from language code
 * @param {string} languageCode - Language code (e.g., 'en-US', 'zh-CN')
 * @returns {string} Two-letter country code
 */
export const getCountryCodeFromLanguage = (languageCode) => {
  if (!languageCode) return "";

  // Check special mappings first
  if (LANGUAGE_COUNTRY_MAPPINGS[languageCode]) {
    return LANGUAGE_COUNTRY_MAPPINGS[languageCode];
  }

  // Extract country code from language code (e.g., "en-US" -> "US")
  const parts = languageCode.split("-");
  return parts.length > 1 ? parts[1] : parts[0].toUpperCase();
};

/**
 * Get both flag emoji and country code
 * @param {string} languageCode - Language code
 * @returns {Object} Object containing flag emoji and country code
 */
export const getFlagInfo = (languageCode) => {
  const countryCode = getCountryCodeFromLanguage(languageCode);
  return {
    countryCode,
    flagEmoji: getFlagEmoji(countryCode),
  };
};

/**
 * Format language display with flag
 * @param {string} languageCode - Language code
 * @param {string} displayName - Display name of the language
 * @returns {Object} Formatted display info with flag
 */
export const formatLanguageDisplay = (languageCode) => {
  const { flagEmoji } = getFlagInfo(languageCode);
  const name = getLanguageName(languageCode);
  return {
    display: `${flagEmoji} ${name}`,
    flagEmoji,
    languageCode,
    name,
  };
};

/**
 * Check if the language code is valid
 * @param {string} languageCode - Language code to validate
 * @returns {boolean} Whether the language code is valid
 */
export const isValidLanguageCode = (languageCode) => {
  if (!languageCode) return false;

  // Basic format validation (e.g., 'en-US', 'zh-CN')
  const formatRegex = /^[a-z]{2,3}(-[A-Z]{2,3})?$/;
  return formatRegex.test(languageCode);
};

/**
 * Add a new function to get language name
 * @param {string} languageCode - Language code
 * @returns {string} Language name
 */
export const getLanguageName = (languageCode) => {
  const languages = {
    "en-US": "English (US)",
    "en-GB": "English (UK)",
    "en-AU": "English (Australia)",
    "en-CA": "English (Canada)",
    "en-NZ": "English (New Zealand)",
    "en-IE": "English (Ireland)",
    "en-ZA": "English (South Africa)",
    // ... add all languages from LanguageSelector
    // You can copy the full list from the languages array in LanguageSelector
  };
  return languages[languageCode] || languageCode;
};

/**
 * Add a new function to get both flag and language info
 * @param {string} languageCode - Language code
 * @returns {Object} Object containing flag emoji, country code, language name, and language code
 */
export const getLanguageInfo = (languageCode) => {
  const { countryCode, flagEmoji } = getFlagInfo(languageCode);
  const name = getLanguageName(languageCode);
  return {
    countryCode,
    flagEmoji,
    name,
    languageCode,
  };
};

/**
 * Usage examples:
 *
 * // Get flag emoji
 * const flag = getFlagEmoji('US'); // 🇺🇸
 *
 * // Get country code from language
 * const countryCode = getCountryCodeFromLanguage('en-US'); // 'US'
 *
 * // Get complete flag info
 * const { countryCode, flagEmoji } = getFlagInfo('en-US');
 *
 * // Format language display
 * const { display } = formatLanguageDisplay('en-US', 'English (US)');
 */
