import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Box,
} from "@mui/material";
import {
  Layers,
  FolderOpen,
  Image as ImageIcon,
  PenTool,
  MessageSquare,
  Zap,
  Facebook,
  BookOpen,
  ExternalLink,
  MessageCircle,
  Lightbulb,
} from "lucide-react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useLimits } from "../context/LimitsContext";
import SidenavCredits from "./SidenavCredits";

const drawerWidth = 280;
const collapsedDrawerWidth = 70;
const HOVER_COLOR = "#5c016f";

const CustomTooltip = ({ children, title, disabled }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const ref = useRef(null);

  useEffect(() => {
    if (isHovered && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY + rect.height / 2,
        left: rect.right + window.scrollX + 10,
      });
    }
  }, [isHovered]);

  if (!disabled) {
    return children;
  }

  return (
    <div
      ref={ref}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: `${position.top}px`,
              left: `${position.left}px`,
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "4px",
              fontSize: "14px",
              whiteSpace: "nowrap",
              zIndex: 9999,
            }}
          >
            {title}
          </div>,
          document.body
        )}
    </div>
  );
};

function Sidenav({ open, onClose, isMobile }) {
  const theme = useTheme();
  const location = useLocation();
  const sidenavRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { limits } = useLimits();

  const menuItems = [
    {
      icon: <Layers sx={{ color: "text.secondary" }} />,
      text: "Dashboard",
      path: "/",
    },
    {
      icon: <FolderOpen sx={{ color: "text.secondary" }} />,
      text: "My Projects",
      path: "/projects",
    },
    {
      icon: <Lightbulb sx={{ color: "text.secondary" }} />,
      text: "Blog Post Ideas",
      path: "/blog-post-ideas",
    },
    {
      icon: <MessageSquare sx={{ color: "text.secondary" }} />,
      text: "Social-First Blog Posts",
      path: "/social-first",
    },
    {
      icon: <Zap sx={{ color: "text.secondary" }} />,
      text: "Status Post Generator",
      path: "/status-post-generator",
    },
    {
      icon: <ImageIcon sx={{ color: "text.secondary" }} />,
      text: "Meme Generator",
      path: "/meme-generator",
    },
    {
      icon: <Facebook sx={{ color: "text.secondary" }} />,
      text: "Facebook Ad Creator",
      path: "/facebook-ad-creator",
    },
    {
      icon: <ExternalLink sx={{ color: "text.secondary" }} />,
      text: "Traffic Driving Image Post",
      path: "/traffic-image",
      disabled: true,
      tooltip: "Coming December 11th",
    },
    {
      icon: <BookOpen sx={{ color: "text.secondary" }} />,
      text: "Training",
      path: "/training",
      disabled: true,
      tooltip: "Coming December 10th",
    },
    {
      icon: <MessageCircle sx={{ color: "text.secondary" }} />,
      text: "NSL Chat",
      path: "/nsl-chat",
      disabled: true,
      tooltip: "Coming December 12th",
    },
  ];

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
        if (isMobile) {
          onClose();
        } else {
          setIsExpanded(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, onClose]);

  return (
    <>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClose={onClose}
        ref={sidenavRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: isMobile
            ? drawerWidth
            : isExpanded
            ? drawerWidth
            : collapsedDrawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isMobile
              ? drawerWidth
              : isExpanded
              ? drawerWidth
              : collapsedDrawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#ffffff",
            borderRight: `1px solid ${theme.palette.divider}`,
            position: "fixed",
            top: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        <Box sx={{ height: 64, flexShrink: 0 }} />
        <Box sx={{ flexGrow: 1, mb: 2 }}>
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <CustomTooltip title={item.tooltip} disabled={item.disabled}>
                  <ListItem
                    button
                    component={item.disabled ? 'div' : RouterLink}
                    to={!item.disabled ? item.path : undefined}
                    selected={
                      location.pathname === item.path ||
                      (item.path === '/meme-generator' && location.pathname.startsWith('/meme-generator/')) ||
                      (item.subItems &&
                        item.subItems.some(
                          (subItem) => location.pathname === subItem.path
                        ))
                    }
                    sx={{
                      justifyContent:
                        isExpanded || isMobile ? "flex-start" : "center",
                      px: isExpanded || isMobile ? 3 : 1,
                      opacity: item.disabled ? 0.5 : 1,
                      cursor: item.disabled ? 'not-allowed' : 'pointer',
                      "& .MuiTypography-root": {
                        fontSize: "0.875rem",
                      },
                      "&:hover": {
                        backgroundColor: "rgba(92, 1, 111, 0.04)",
                        "& .MuiListItemIcon-root, & .MuiTypography-root": {
                          color: HOVER_COLOR,
                        },
                      },
                      "&.Mui-selected": {
                        backgroundColor: "rgba(92, 1, 111, 0.08)",
                        "& .MuiListItemIcon-root, & .MuiTypography-root": {
                          color: HOVER_COLOR,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(92, 1, 111, 0.12)",
                        },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isExpanded || isMobile ? 3 : 0,
                        justifyContent: "center",
                        color: "text.secondary",
                        "& svg": {
                          width: 20,
                          height: 20,
                        },
                        transition: "color 0.2s",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    {(isExpanded || isMobile) && (
                      <ListItemText
                        primary={item.text}
                        sx={{
                          color: "inherit",
                          opacity: isExpanded || isMobile ? 1 : 0,
                          transition: theme.transitions.create("opacity"),
                          "& .MuiListItemText-primary": {
                            fontSize: "0.875rem",
                          },
                        }}
                      />
                    )}
                  </ListItem>
                </CustomTooltip>

                {item.subItems && (isExpanded || isMobile) && (
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        button
                        component={RouterLink}
                        to={subItem.path}
                        selected={location.pathname === subItem.path}
                        sx={{
                          pl: 7,
                          py: 1,
                          "&.Mui-selected": {
                            backgroundColor: "rgba(92, 1, 111, 0.08)",
                            "& .MuiListItemIcon-root, & .MuiTypography-root": {
                              color: HOVER_COLOR,
                            },
                            "&:hover": {
                              backgroundColor: "rgba(92, 1, 111, 0.12)",
                            },
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: "center",
                            color: "text.secondary",
                            "& svg": {
                              width: 20,
                              height: 20,
                            },
                            transition: "color 0.2s",
                          }}
                        >
                          {subItem.icon}
                        </ListItemIcon>
                        {(isExpanded || isMobile) && (
                          <ListItemText
                            primary={subItem.text}
                            sx={{
                              color: "inherit",
                              opacity: isExpanded || isMobile ? 1 : 0,
                              transition: theme.transitions.create("opacity"),
                              "& .MuiListItemText-primary": {
                                fontSize: "0.875rem",
                              },
                            }}
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
        <SidenavCredits isExpanded={isExpanded || isMobile} />
      </Drawer>
    </>
  );
}

export default Sidenav;
