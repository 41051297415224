import React from "react";
import {
  Box,
  Typography,
  TextField,
  Slider,
  FormControl,
  Select,
  MenuItem,
  Button,
  Stack,
  CircularProgress,
  TextareaAutosize,
} from "@mui/material";
import { Download, PlusCircle, Wand2 } from "lucide-react";

const MemeUploadEditor = ({
  from,
  memeText,
  textFields,
  onTextFieldsChange,
  fontSize,
  textAreaWidth,
  strokeWidth,
  textFillColor,
  textStrokeColor,
  selectedFont,
  fontOptions,
  onMemeTextChange,
  onFontSizeChange,
  onTextAreaWidthChange,
  onStrokeWidthChange,
  onTextFillColorChange,
  onTextStrokeColorChange,
  onFontChange,
}) => {
  // Helper function to calculate rows based on text length
  const calculateRows = (text) => {
    if (!text) return 1;
    const words = text.split(" ").length;
    return words > 6 ? 2 : 1;
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Text Fields Section */}
      {from === "memeupload" ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            Meme Text
          </Typography>
          <TextareaAutosize
            value={memeText}
            onChange={(e) => onMemeTextChange(e.target.value)}
            style={{
              width: "100%",
              padding: "12px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              fontFamily: "inherit",
              fontSize: "16px",
              minHeight: "100px",
              resize: "vertical",
              backgroundColor: "#fff",
            }}
          />
        </Box>
      ) : (
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
            Meme Text
          </Typography>
          {Object.entries(textFields || {})
            .sort()
            .map(([key, value], index) => (
              <Box
                key={key}
                sx={{
                  mb: index < Object.keys(textFields || {}).length - 1 ? 4 : 3,
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 1, color: "text.secondary" }}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}:
                </Typography>
                <TextareaAutosize
                  value={value}
                  onChange={(e) => {
                    const newTextFields = { ...textFields };
                    newTextFields[key] = e.target.value;
                    onTextFieldsChange(newTextFields);
                  }}
                  placeholder={`Enter text for ${key}`}
                  style={{
                    width: "100%",
                    padding: "8px 12px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    fontFamily: "inherit",
                    fontSize: "14px",
                    minHeight: calculateRows(value) === 1 ? "36px" : "54px",
                    maxHeight: "54px", // Limit to 2 lines
                    resize: "none",
                    backgroundColor: "#fff",
                    lineHeight: "18px",
                  }}
                  maxLength={100} // Limit character count
                />
              </Box>
            ))}
        </Box>
      )}
      {/* Font Selection */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
          Font
        </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedFont}
            onChange={(e) => onFontChange(e.target.value)}
            sx={{
              height: "48px",
              bgcolor: "#fff",
              "&:hover": {
                bgcolor: "#fff",
              },
            }}
          >
            {fontOptions.map((font) => (
              <MenuItem
                key={font.value}
                value={font.value}
                sx={{ fontFamily: font.value }}
              >
                {font.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {/* Controls Row */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: 2,
          mb: 4,
        }}
      >
        {/* Font Size */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
            Font Size: {fontSize}%
          </Typography>
          <Slider
            value={fontSize}
            onChange={(_, value) => onFontSizeChange(value)}
            min={5}
            max={100}
            sx={{
              color: "#1976d2",
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
              },
            }}
          />
        </Box>

        {/* Text Width */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
            Text Width: {textAreaWidth}%
          </Typography>
          <Slider
            value={textAreaWidth}
            onChange={(_, value) => onTextAreaWidthChange(value)}
            min={20}
            max={100}
            sx={{
              color: "#1976d2",
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
              },
            }}
          />
        </Box>

        {/* Outline Thickness */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
            Outline: {strokeWidth}%
          </Typography>
          <Slider
            value={strokeWidth}
            onChange={(_, value) => onStrokeWidthChange(value)}
            min={0}
            max={50}
            sx={{
              color: "#1976d2",
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
              },
            }}
          />
        </Box>
      </Box>
      {/* Color Pickers */}
      <Box
        sx={{
          mb: 4,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 3,
        }}
      >
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            Text Color
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "48px",
              borderRadius: 1,
              overflow: "hidden",
              border: "2px solid #e0e0e0",
            }}
          >
            <input
              type="color"
              value={textFillColor}
              onChange={(e) => onTextFillColorChange(e.target.value)}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                padding: 0,
                margin: 0,
              }}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 500 }}>
            Outline Color
          </Typography>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "48px",
              borderRadius: 1,
              overflow: "hidden",
              border: "2px solid #e0e0e0",
            }}
          >
            <input
              type="color"
              value={textStrokeColor}
              onChange={(e) => onTextStrokeColorChange(e.target.value)}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                padding: 0,
                margin: 0,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MemeUploadEditor;
