import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Autocomplete,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import LanguageSelector, { languages } from "../common/LanguageSelector";
import BlogPostEditor from "./BlogPostEditor";
import { toast } from "react-toastify";
import generalApi from "../../api/generalApi";
import AddIcon from "@mui/icons-material/Add";
import AddProjectModal from "../Project/AddProjectModal";
import ReactCountryFlag from "react-country-flag";
import { useLimits } from "../../context/LimitsContext";
import { Wand2 } from "lucide-react";

const LANGUAGE_STORAGE_KEY = "lastSelectedLanguage";
const PROJECT_STORAGE_KEY = 'lastSelectedProject';

const WritePost = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState(
    () => localStorage.getItem(LANGUAGE_STORAGE_KEY) || "en-US"
  );
  const [editorOpen, setEditorOpen] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [generatingArticle, setGeneratingArticle] = useState(false);
  const [articleLength, setArticleLength] = useState(0);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const { fetchLimits } = useLimits();

  const lengthOptions = [
    { value: 0, label: "Auto (Recommended)" },
    { value: 1, label: "Short" },
    { value: 2, label: "Medium" },
    { value: 3, label: "Long" },
  ];

  useEffect(() => {
    fetchProjects();
  }, [language]);

  const fetchProjects = async () => {
    try {
      setProjectsLoading(true);
      setError(null);
      const response = await generalApi.project.getProjectsLists();
      const filteredProjects = (response.results || []).filter(
        (project) => project.language === language
      );
      setProjects(filteredProjects);
      
      const savedProjectId = localStorage.getItem(PROJECT_STORAGE_KEY);
      if (savedProjectId) {
        const savedProject = filteredProjects.find(p => p._id === savedProjectId);
        if (savedProject) {
          setSelectedProject(savedProject);
        } else {
          localStorage.removeItem(PROJECT_STORAGE_KEY);
        }
      }
    } catch (err) {
      setError("Failed to load projects");
    } finally {
      setProjectsLoading(false);
    }
  };

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setSelectedProject(null);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, newLanguage);
    localStorage.removeItem(PROJECT_STORAGE_KEY);
  };

  const handleWriteArticle = async () => {
    if (!title.trim()) {
      toast.error("Please enter a title");
      return;
    }

    if (!selectedProject) {
      toast.error("Please select a project");
      return;
    }

    try {
      setGeneratingArticle(true);

      // Start the 5-second timer for fetching limits as soon as the button is clicked
      setTimeout(async () => {
        await fetchLimits();
      }, 5000);

      const response = await generalApi.prompts.generateBlogPost(
        language,
        title,
        description,
        articleLength
      );

      const htmlContent = response.results
        .replace(/\n\n/g, "\n")
        .replace(/\n/g, "")
        .replace(/<p><br><\/p>/g, "")
        .replace(/<p>\s*<\/p>/g, "")
        .replace(/>\s+</g, "><");

      // Save the blog post
      try {
        const savedPost = await generalApi.project.addBlogPost({
          projectId: selectedProject._id,
          title: title,
          idea: description,
          content: htmlContent,
        });

        if (savedPost?.results?._id) {
          const url = `/social-first-blog-posts/${savedPost.results._id}`;
          
          // Only show success message and open window after we have confirmation
          toast.success("Blog post saved successfully!");
          
          const newWindow = window.open(url, "_blank");

          if (
            !newWindow ||
            newWindow.closed ||
            typeof newWindow.closed === "undefined"
          ) {
            toast.info("Pop-up was blocked. Click here to open the post.", {
              onClick: () => window.open(url, "_blank"),
            });
          }
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error saving blog post:", error);
        toast.error("Failed to save blog post");
      }
    } catch (error) {
      console.error("Error generating article:", error);
      toast.error(error.message || "Failed to generate article");
    } finally {
      setGeneratingArticle(false);
    }
  };

  const handleProjectAdded = async (newProject) => {
    await fetchProjects();
    setIsAddModalOpen(false);
  };

  const handleCloseEditor = () => {
    setEditorOpen(false);
    setEditorContent("");
  };

  if (editorOpen) {
    return (
      <BlogPostEditor
        title={title}
        content={editorContent}
        onContentChange={setEditorContent}
        loading={generatingArticle}
        onBack={handleCloseEditor}
      />
    );
  }

  return (
    <Box
      sx={{
        ml: { xs: 0, md: "70px" },
        minHeight: { xs: 'auto', md: 'calc(100vh - 74px)' },
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <Container 
        maxWidth="xl" 
        sx={{ 
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ 
          width: "100%", 
          maxWidth: "800px",
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
          <Typography
            variant="h5"
            component="h1"
            sx={{
              mb: 4,
              fontWeight: "bold",
            }}
          >
            Write Blog Post
          </Typography>

          <Paper
            elevation={0}
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              p: 4,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              minHeight: 'min-content',
            }}
          >
            <Box sx={{ 
              display: "flex", 
              flexDirection: "column", 
              gap: 3,
              height: '100%',
            }}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                required
              />

              <LanguageSelector
                value={language}
                onChange={handleLanguageChange}
              />

              <Typography 
                variant="body2" 
                sx={{ 
                  mb: -2, 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <ReactCountryFlag
                  countryCode={languages.find(l => l.code === language)?.countryCode}
                  svg
                  style={{ width: '1em', height: '1em' }}
                />
                {projects.length > 0 
                  ? `Showing Projects in ${languages.find(l => l.code === language)?.name || language}`
                  : `No projects in ${languages.find(l => l.code === language)?.name || language}, please create a project`
                }
              </Typography>

              <Autocomplete
                options={[
                  ...projects,
                  {
                    _id: "add-new",
                    name: "Add New Project",
                    isAddOption: true,
                  },
                ]}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(_, value) => {
                  if (value?.isAddOption) {
                    setIsAddModalOpen(true);
                    setSelectedProject(null);
                    localStorage.removeItem(PROJECT_STORAGE_KEY);
                  } else {
                    setSelectedProject(value);
                    if (value?._id) {
                      localStorage.setItem(PROJECT_STORAGE_KEY, value._id);
                    } else {
                      localStorage.removeItem(PROJECT_STORAGE_KEY);
                    }
                  }
                }}
                value={selectedProject}
                loading={projectsLoading}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                required
                noOptionsText={
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 1,
                      color: 'text.secondary'
                    }}
                  >
                    No projects in {languages.find(l => l.code === language)?.name || language}, please create a project
                  </Box>
                }
                renderOption={(props, option) => {
                  const { key, ...otherProps } = props;

                  if (option.isAddOption) {
                    return (
                      <Box
                        component="li"
                        key={option._id}
                        {...otherProps}
                        sx={{
                          "&.MuiAutocomplete-option": {
                            color: "#9c27b0",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (option.isAddOption) {
                            setIsAddModalOpen(true);
                          }
                        }}
                      >
                        <AddIcon sx={{ mr: 1 }} />
                        {option.name}
                      </Box>
                    );
                  }

                  return (
                    <li key={option._id} {...otherProps}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ReactCountryFlag
                          countryCode={languages.find(l => l.code === language)?.countryCode}
                          svg
                          style={{ width: '1em', height: '1em' }}
                        />
                        {option.name}
                      </Box>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Project"
                    placeholder="Search projects..."
                    error={!!error}
                    helperText={error}
                    required
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedProject && (
                        <ReactCountryFlag
                          countryCode={languages.find(l => l.code === language)?.countryCode}
                          svg
                          style={{ 
                            width: '1em', 
                            height: '1em',
                            marginLeft: '8px',
                            marginRight: '4px'
                          }}
                        />
                      ),
                      endAdornment: (
                        <>
                          {projectsLoading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description (Optional)"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
              />

              <Box sx={{ display: "flex", gap: 1 }}>
                {lengthOptions.map((option) => (
                  <Button
                    key={option.value}
                    size="small"
                    variant={articleLength === option.value ? "contained" : "outlined"}
                    onClick={() => setArticleLength(option.value)}
                    sx={{
                      flex: 1,
                      minWidth: 0,
                      bgcolor: articleLength === option.value ? "rgba(156, 39, 176, 0.08)" : "transparent",
                      borderColor: articleLength === option.value ? "#9c27b0" : "rgba(0, 0, 0, 0.12)",
                      color: "#9c27b0",
                      "&:hover": {
                        bgcolor: articleLength === option.value ? "rgba(156, 39, 176, 0.12)" : "rgba(156, 39, 176, 0.04)",
                        borderColor: "#9c27b0",
                      },
                      textTransform: "none",
                      py: 0.5,
                    }}
                  >
                    {option.label}
                  </Button>
                ))}
              </Box>

              <Tooltip title="5 Credits" arrow>
                <Button
                  variant="contained"
                  onClick={handleWriteArticle}
                  disabled={generatingArticle}
                  sx={{
                    bgcolor: "#9c27b0",
                    "&:hover": { bgcolor: "#7b1fa2" },
                    textTransform: "none",
                  }}
                >
                  {generatingArticle ? (
                    "Generating... You can keep using other parts of the app..."
                  ) : (
                    <>
                      Write Article
                      <Wand2 size={18} style={{ marginLeft: 8 }} />
                    </>
                  )}
                </Button>
              </Tooltip>
            </Box>
          </Paper>
        </Box>

        <AddProjectModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onProjectAdded={handleProjectAdded}
        />
      </Container>
    </Box>
  );
};

export default WritePost; 