// src/components/PasswordReset.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Container,
} from "@mui/material";
import { toast } from "react-toastify";
import authApi from "../api/authApi";
import { useErrorHandler } from "../hooks/useErrorHandler";
import logo from "../assets/mainLogo.png";

function PasswordReset() {
  const handleError = useErrorHandler();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(authApi.checkAuthStatus());
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter your email address");
      return;
    }
    setLoading(true);
    try {
      await authApi.resetPassword(email);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 6, textAlign: 'center' }}>
      <img
        src={logo}
        alt="Logo"
        style={{
          width: "100px",
          marginBottom: "1.5rem",
          marginRight: "-3px",
        }}
      />
      <Card>
        <CardContent sx={{ py: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            {isLoggedIn && (
              <IconButton onClick={handleBack} sx={{ mr: 1 }}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h5" component="h2">
              Password Reset
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              margin="normal"
              sx={{ mt: 1 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Request Password Reset"}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default PasswordReset;
