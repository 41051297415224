import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Box,
  Tooltip,
} from "@mui/material";
import generalApi from "../../../api/generalApi";
import LanguageSelector, { languages } from "../../common/LanguageSelector";
import { Wand2 } from "lucide-react";

const MemeModal = ({ open, onClose, onSubmit, title, isTemplate = false, lastUsedSettings = null }) => {
  // Initialize formData with values from localStorage immediately
  const initialSettings = (() => {
    try {
      const stored = localStorage.getItem('lastMemeSettings');
      if (stored) {
        const settings = JSON.parse(stored);
        return {
          language: localStorage.getItem("selectedLanguage") || languages[0]?.code || "en-US",
          description: settings.description || "",
          goal: settings.goal || "funny",
        };
      }
    } catch (error) {
      console.error('Error loading initial settings:', error);
    }
    return {
      language: localStorage.getItem("selectedLanguage") || languages[0]?.code || "en-US",
      description: "",
      goal: "funny",
    };
  })();

  const [formData, setFormData] = useState(initialSettings);
  const [isLoading, setIsLoading] = useState(false);
  const [generatedMeme, setGeneratedMeme] = useState(null);

  useEffect(() => {
    console.log("DEBUG: Initial localStorage content:", localStorage.getItem('lastMemeSettings'));
  }, []);

  // Load last used settings from localStorage
  const getLastUsedSettings = () => {
    const stored = localStorage.getItem('lastMemeSettings');
    console.log("DEBUG: Loading from localStorage:", stored);
    try {
      if (stored) {
        const settings = JSON.parse(stored);
        console.log("DEBUG: Parsed settings:", settings);
        return settings;
      }
    } catch (error) {
      console.error('DEBUG: Error loading last meme settings:', error);
    }
    console.log("DEBUG: Returning default settings");
    return {
      goal: 'funny',
      description: '',
    };
  };

  // Save settings to localStorage
  const saveToLocalStorage = (newData) => {
    try {
      const dataToSave = {
        goal: newData.goal,
        description: newData.description,
      };
      console.log("DEBUG: Saving to localStorage:", dataToSave);
      localStorage.setItem('lastMemeSettings', JSON.stringify(dataToSave));
      console.log("DEBUG: Verification - Just saved:", localStorage.getItem('lastMemeSettings'));
    } catch (error) {
      console.error('DEBUG: Error saving meme settings:', error);
    }
  };

  // Handle goal change
  const handleGoalChange = (e) => {
    const newGoal = e.target.value;
    console.log("DEBUG: Goal changed to:", newGoal);
    const newData = {
      ...formData,
      goal: newGoal,
    };
    console.log("DEBUG: New form data:", newData);
    setFormData(newData);
    saveToLocalStorage(newData);
  };

  // Handle description change
  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    console.log("DEBUG: Description changed to:", newDescription);
    const newData = {
      ...formData,
      description: newDescription,
    };
    console.log("DEBUG: New form data:", newData);
    setFormData(newData);
    saveToLocalStorage(newData);
  };

  // Load initial data when modal opens
  useEffect(() => {
    if (open) {
      const lastSettings = getLastUsedSettings();
      console.log("DEBUG: Modal opened, last settings:", lastSettings);
      const savedLanguage = localStorage.getItem("selectedLanguage") || languages[0]?.code || "en-US";
      console.log("DEBUG: Saved language:", savedLanguage);
      
      const newFormData = {
        language: savedLanguage,
        description: lastSettings.description || formData.description || "",
        goal: lastSettings.goal || formData.goal || "funny",
      };
      console.log("DEBUG: Setting initial form data:", newFormData);
      setFormData(newFormData);
      setGeneratedMeme(null);
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await onSubmit(formData);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleClose = () => {
    saveToLocalStorage(formData);
    onClose();
  };

  // Simplified form validation
  const isFormValid = Boolean(
    formData.language &&
    formData.description?.trim() &&
    formData.goal
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {isTemplate ? "Generate Meme: " : "Use Meme: "}
        <Typography
          component="span"
          sx={{ color: "#9c27b0", fontWeight: "600" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ pt: 1 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Goal</InputLabel>
            <Select
              value={formData.goal || "funny"}
              label="Goal"
              onChange={handleGoalChange}
            >
              <MenuItem value="funny">Funny</MenuItem>
              <MenuItem value="engagement">Engagement</MenuItem>
              <MenuItem value="controversial">Controversial</MenuItem>
              <MenuItem value="promotional">Promotional</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ mb: 2 }}>
            <LanguageSelector
              value={formData.language}
              onChange={(e) =>
                setFormData({ ...formData, language: e.target.value })
              }
            />
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            label={
              isTemplate ? "What should the meme be about?" : "Description"
            }
            value={formData.description}
            onChange={handleDescriptionChange}
            inputProps={{ maxLength: 500 }}
            helperText={`${formData.description.length}/500`}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button onClick={handleClose} variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Tooltip title="1 Credit" arrow>
            <Button
              type="submit"
              variant="contained"
              disabled={!isFormValid || isLoading}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                minWidth: 100,
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  Generate
                  <Wand2 size={18} style={{ marginLeft: 8 }} />
                </>
              )}
            </Button>
          </Tooltip>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MemeModal;
