import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';

const SubscriptionModal = ({ open, onClose, subscriptionUrl }) => {
  const handleUpgrade = () => {
    if (subscriptionUrl) {
      window.open(subscriptionUrl, '_blank');
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Subscription Limit Reached</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1" gutterBottom>
            You've reached your subscription limit for this feature.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            To continue using this feature and unlock more capabilities, please upgrade your subscription.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
        <Button 
          onClick={handleUpgrade}
          variant="contained"
          sx={{ 
            bgcolor: '#5c016f',
            '&:hover': {
              bgcolor: '#4a0159'
            }
          }}
        >
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionModal; 