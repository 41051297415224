import React, { useState } from "react";
import {
  Box,
  Typography,
  Slider,
  Tabs,
  Tab,
  TextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";

const EditorTabs = {
  TOP_TEXT: 'TOP_TEXT',
  BOTTOM_TEXT: 'BOTTOM_TEXT',
  IMAGE: 'IMAGE',
  BACKGROUND: 'BACKGROUND',
  EMOJI: 'EMOJI'
};

const FacebookAdUploadEditor = ({
  from,
  memeText,
  textFields,
  onTextFieldsChange,
  fontSize,
  textAreaWidth,
  strokeWidth,
  textFillColor,
  textStrokeColor,
  selectedFont,
  fontOptions,
  onMemeTextChange,
  onFontSizeChange,
  onTextAreaWidthChange,
  onStrokeWidthChange,
  onTextFillColorChange,
  onTextStrokeColorChange,
  onFontChange,
  backgroundColor,
  onBackgroundColorChange,
  bottomText,
  bottomFontSize,
  bottomTextAreaWidth,
  bottomStrokeWidth,
  bottomTextFillColor,
  bottomTextStrokeColor,
  bottomFont,
  onBottomTextChange,
  onBottomFontSizeChange,
  onBottomTextAreaWidthChange,
  onBottomStrokeWidthChange,
  onBottomTextFillColorChange,
  onBottomTextStrokeColorChange,
  onBottomFontChange,
  generatedMeme,
  selectedTab,
  onTabChange,
  imageOffsetY,
  onImageOffsetYChange,
  imageScale,
  onImageScaleChange,
  emojiSize,
  onEmojiSizeChange,
  customEmoji,
  onCustomEmojiChange,
  topTextHeight,
  onTopTextHeightChange,
  bottomTextHeight,
  onBottomTextHeightChange,
  emojiPosition,
  onEmojiPositionChange,
  imagePosition,
  onImagePositionChange,
  TOP_HEIGHT,
  BOTTOM_HEIGHT,
  CANVAS_SIZE,
  IMAGE_AREA_HEIGHT,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs 
        value={selectedTab} 
        onChange={(e, newValue) => onTabChange(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab 
          label="Top" 
          value={EditorTabs.TOP_TEXT} 
          title="Edit top text properties"
        />
        {generatedMeme && (
          <Tab 
            label="Bottom" 
            value={EditorTabs.BOTTOM_TEXT} 
            title="Edit bottom text properties"
          />
        )}
        <Tab 
          label="Image" 
          value={EditorTabs.IMAGE} 
          title="Adjust image position and size"
        />
        <Tab 
          label="Background" 
          value={EditorTabs.BACKGROUND} 
          title="Change background color"
        />
        {generatedMeme && (
          <Tab 
            label="Emoji" 
            value={EditorTabs.EMOJI} 
            title="Add and customize emoji"
          />
        )}
      </Tabs>

      {selectedTab === EditorTabs.TOP_TEXT && (
        <Box>
          <TextareaAutosize
            value={memeText}
            onChange={(e) => onMemeTextChange(e.target.value)}
            placeholder="Enter top text..."
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "16px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <Typography gutterBottom>Font</Typography>
          <Select
            value={selectedFont}
            onChange={(e) => onFontChange(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          >
            {fontOptions.map((font) => (
              <MenuItem
                key={font.value}
                value={font.value}
                sx={{ fontFamily: font.value }}
              >
                {font.label}
              </MenuItem>
            ))}
          </Select>
          
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography gutterBottom>Text Color</Typography>
              <input
                type="color"
                value={textFillColor}
                onChange={(e) => onTextFillColorChange(e.target.value)}
                style={{ width: "100%", height: "40px" }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography gutterBottom>Outline Color</Typography>
              <input
                type="color"
                value={textStrokeColor}
                onChange={(e) => onTextStrokeColorChange(e.target.value)}
                style={{ width: "100%", height: "40px" }}
              />
            </Box>
          </Box>

          <Typography gutterBottom>Outline Width</Typography>
          <Slider
            value={strokeWidth}
            onChange={(_, value) => onStrokeWidthChange(value)}
            min={0}
            max={50}
            sx={{ mb: 2 }}
          />
          <Typography gutterBottom>Text Height (%)</Typography>
          <Slider
            value={topTextHeight}
            onChange={(_, value) => onTopTextHeightChange(value)}
            min={10}
            max={30}
          />
        </Box>
      )}

      {selectedTab === EditorTabs.BOTTOM_TEXT && generatedMeme && (
        <Box>
          <TextareaAutosize
            value={bottomText}
            onChange={(e) => onBottomTextChange(e.target.value)}
            placeholder="Enter bottom text..."
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "16px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <Typography gutterBottom>Font</Typography>
          <Select
            value={bottomFont}
            onChange={(e) => onBottomFontChange(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          >
            {fontOptions.map((font) => (
              <MenuItem
                key={font.value}
                value={font.value}
                sx={{ fontFamily: font.value }}
              >
                {font.label}
              </MenuItem>
            ))}
          </Select>
          
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography gutterBottom>Text Color</Typography>
              <input
                type="color"
                value={bottomTextFillColor}
                onChange={(e) => onBottomTextFillColorChange(e.target.value)}
                style={{ width: "100%", height: "40px" }}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography gutterBottom>Outline Color</Typography>
              <input
                type="color"
                value={bottomTextStrokeColor}
                onChange={(e) => onBottomTextStrokeColorChange(e.target.value)}
                style={{ width: "100%", height: "40px" }}
              />
            </Box>
          </Box>

          <Typography gutterBottom>Outline Width</Typography>
          <Slider
            value={bottomStrokeWidth}
            onChange={(_, value) => onBottomStrokeWidthChange(value)}
            min={0}
            max={50}
            sx={{ mb: 2 }}
          />
          <Typography gutterBottom>Text Height (%)</Typography>
          <Slider
            value={bottomTextHeight}
            onChange={(_, value) => onBottomTextHeightChange(value)}
            min={10}
            max={30}
          />
        </Box>
      )}

      {selectedTab === EditorTabs.IMAGE && generatedMeme && (
        <Box>
          <Typography gutterBottom>Horizontal Position (%)</Typography>
          <Slider
            value={imagePosition ? imagePosition.x : 0}
            onChange={(_, value) => {
              onImagePositionChange({
                x: value,
                y: imagePosition ? imagePosition.y : 0
              });
            }}
            min={-100}
            max={100}
            step={5}
            marks={[
              { value: -100, label: '-100%' },
              { value: 0, label: 'Center' },
              { value: 100, label: '100%' }
            ]}
          />
          <Typography gutterBottom>Vertical Position (%)</Typography>
          <Slider
            value={imagePosition ? imagePosition.y : 0}
            onChange={(_, value) => {
              onImagePositionChange({
                x: imagePosition ? imagePosition.x : 0,
                y: value
              });
            }}
            min={-100}
            max={100}
            step={5}
            marks={[
              { value: -100, label: '-100%' },
              { value: 0, label: 'Center' },
              { value: 100, label: '100%' }
            ]}
          />
          <Typography gutterBottom>Size</Typography>
          <Slider
            value={imageScale}
            onChange={(_, value) => onImageScaleChange(value)}
            min={0.5}
            max={2}
            step={0.1}
          />
        </Box>
      )}

      {selectedTab === EditorTabs.BACKGROUND && (
        <Box>
          <Typography gutterBottom>Background Color</Typography>
          <input
            type="color"
            value={backgroundColor}
            onChange={(e) => onBackgroundColorChange(e.target.value)}
            style={{ width: "100%", height: "40px" }}
          />
        </Box>
      )}

      {selectedTab === EditorTabs.EMOJI && generatedMeme && (
        <Box>
          <Typography gutterBottom>Select Emoji</Typography>
          <Box 
            sx={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(8, 1fr)',
              gap: 1,
              mb: 3,
              '& button': {
                width: '100%',
                aspectRatio: '1',
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
                '&.selected': {
                  backgroundColor: '#f0f0f0',
                  borderColor: '#9c27b0',
                }
              }
            }}
          >
            {/* Pointing fingers (default) */}
            <button 
              onClick={() => onCustomEmojiChange('👇')}
              className={customEmoji === '👇' ? 'selected' : ''}
            >
              👇
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👇🏻')}
              className={customEmoji === '👇🏻' ? 'selected' : ''}
            >
              👇🏻
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👇🏼')}
              className={customEmoji === '👇🏼' ? 'selected' : ''}
            >
              👇🏼
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👇🏽')}
              className={customEmoji === '👇🏽' ? 'selected' : ''}
            >
              👇🏽
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👇🏾')}
              className={customEmoji === '👇🏾' ? 'selected' : ''}
            >
              👇🏾
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👇🏿')}
              className={customEmoji === '👇🏿' ? 'selected' : ''}
            >
              👇🏿
            </button>
            
            {/* Other pointing gestures */}
            <button 
              onClick={() => onCustomEmojiChange('👆')}
              className={customEmoji === '👆' ? 'selected' : ''}
            >
              👆
            </button>
            <button 
              onClick={() => onCustomEmojiChange('☝️')}
              className={customEmoji === '☝️' ? 'selected' : ''}
            >
              ☝️
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👈')}
              className={customEmoji === '👈' ? 'selected' : ''}
            >
              👈
            </button>
            <button 
              onClick={() => onCustomEmojiChange('👉')}
              className={customEmoji === '👉' ? 'selected' : ''}
            >
              👉
            </button>
            
            {/* Attention-grabbing emojis */}
            <button 
              onClick={() => onCustomEmojiChange('🤯')}
              className={customEmoji === '🤯' ? 'selected' : ''}
            >
              🤯
            </button>
            <button 
              onClick={() => onCustomEmojiChange('😱')}
              className={customEmoji === '😱' ? 'selected' : ''}
            >
              😱
            </button>
            <button 
              onClick={() => onCustomEmojiChange('😮')}
              className={customEmoji === '😮' ? 'selected' : ''}
            >
              😮
            </button>
            <button 
              onClick={() => onCustomEmojiChange('🔥')}
              className={customEmoji === '🔥' ? 'selected' : ''}
            >
              🔥
            </button>
            
            {/* Action/Engagement emojis */}
            <button 
              onClick={() => onCustomEmojiChange('✨')}
              className={customEmoji === '✨' ? 'selected' : ''}
            >
              ✨
            </button>
            <button 
              onClick={() => onCustomEmojiChange('💫')}
              className={customEmoji === '💫' ? 'selected' : ''}
            >
              💫
            </button>
            <button 
              onClick={() => onCustomEmojiChange('❤️')}
              className={customEmoji === '❤️' ? 'selected' : ''}
            >
              ❤️
            </button>
            <button 
              onClick={() => onCustomEmojiChange('💯')}
              className={customEmoji === '💯' ? 'selected' : ''}
            >
              💯
            </button>
            
            {/* More attention emojis */}
            <button 
              onClick={() => onCustomEmojiChange('⭐')}
              className={customEmoji === '⭐' ? 'selected' : ''}
            >
              ⭐
            </button>
            <button 
              onClick={() => onCustomEmojiChange('💥')}
              className={customEmoji === '💥' ? 'selected' : ''}
            >
              💥
            </button>
            <button 
              onClick={() => onCustomEmojiChange('🎯')}
              className={customEmoji === '🎯' ? 'selected' : ''}
            >
              🎯
            </button>
            <button 
              onClick={() => onCustomEmojiChange('💡')}
              className={customEmoji === '💡' ? 'selected' : ''}
            >
              💡
            </button>
          </Box>

          <Typography gutterBottom>Custom Emoji</Typography>
          <TextareaAutosize
            value={customEmoji}
            onChange={(e) => onCustomEmojiChange(e.target.value)}
            placeholder="Or enter custom emoji..."
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "16px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          
          <Typography gutterBottom>Size</Typography>
          <Slider
            value={emojiSize}
            onChange={(_, value) => onEmojiSizeChange(value)}
            min={50}
            max={200}
            sx={{ mb: 3 }}
          />
          <Typography gutterBottom>Distance from Right Edge (px)</Typography>
          <Slider
            value={emojiPosition ? CANVAS_SIZE - emojiPosition.x : 20}
            onChange={(_, value) => onEmojiPositionChange({
              x: CANVAS_SIZE - value,
              y: emojiPosition ? emojiPosition.y : CANVAS_SIZE - 40
            })}
            min={20}
            max={CANVAS_SIZE - 20}
            step={5}
            sx={{ mb: 3 }}
          />
          <Typography gutterBottom>Distance from Bottom Edge (px)</Typography>
          <Slider
            value={emojiPosition ? CANVAS_SIZE - emojiPosition.y : 40}
            onChange={(_, value) => onEmojiPositionChange({
              x: emojiPosition ? emojiPosition.x : CANVAS_SIZE - 20,
              y: CANVAS_SIZE - value
            })}
            min={20}
            max={CANVAS_SIZE - 20}
            step={5}
          />
        </Box>
      )}
    </Box>
  );
};

export default FacebookAdUploadEditor;
