import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon as MenuItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper as TablePaper,
  Link,
} from "@mui/material";
import {
  FolderPlus,
  Folder,
  Search,
  MoreVertical,
  Edit,
  Trash2,
  Eye,
  Download,
  Image as ImageIcon,
} from "lucide-react";
import AddProjectModal from "./AddProjectModal";
import EditProjectModal from "./EditProjectModal";
import { toast } from "react-toastify";
import generalApi from "../../api/generalApi";
import ConfirmationModal from "../common/ConfirmationModal";
import { getFlagInfo, formatLanguageDisplay } from "../../utils/flagUtils";
import ReactCountryFlag from "react-country-flag";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

const ProjectsScreen = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMenuProject, setSelectedMenuProject] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [isItemDeleteModalOpen, setIsItemDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Fetch projects on component mount
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await generalApi.project.getProjects();
      setProjects(response.results || []);
    } catch (err) {
      console.error("Error fetching projects:", err);
      setError("Failed to load projects");
      toast.error("Failed to load projects");
    } finally {
      setLoading(false);
    }
  };

  const handleProjectAdded = async (newProject) => {
    try {
      // Fetch the updated list of projects after adding
      await fetchProjects();
      toast.success("Project created successfully!");
      setIsAddModalOpen(false);
    } catch (err) {
      console.error("Error refreshing projects:", err);
      toast.error("Project created but failed to refresh the list");
    }
  };

  const handleProjectUpdated = async (updatedProject) => {
    try {
      await generalApi.project.updateProject(updatedProject);
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project._id === updatedProject._id ? updatedProject : project
        )
      );
      toast.success("Project updated successfully!");
      setIsEditModalOpen(false);
      setSelectedProject(null);
    } catch (err) {
      console.error("Error updating project:", err);
      toast.error("Failed to update project");
    }
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const filteredProjects = searchTerm
    ? projects.filter((project) => project?.name?.includes(searchTerm))
    : projects;

  const handleMenuOpen = (event, project) => {
    event.stopPropagation(); // Prevent list item click
    setAnchorEl(event.currentTarget);
    setSelectedMenuProject(project);
  };

  const handleMenuClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    setSelectedMenuProject(null);
  };

  const handleEditClick = (event) => {
    handleMenuClose(event);
    setSelectedProject(selectedMenuProject);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    handleMenuClose();
    setProjectToDelete(selectedMenuProject);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!projectToDelete) return;

    try {
      await generalApi.project.deleteProject(projectToDelete._id);
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project._id !== projectToDelete._id)
      );
      toast.success("Project deleted successfully!");

      if (selectedProject?._id === projectToDelete._id) {
        setSelectedProject(null);
        setIsEditModalOpen(false);
      }
    } catch (err) {
      console.error("Error deleting project:", err);
      toast.error("Failed to delete project");
    } finally {
      setIsDeleteModalOpen(false);
      setProjectToDelete(null);
      setSelectedMenuProject(null);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setProjectToDelete(null);
  };

  const handleItemDeleteClick = (item) => {
    setItemToDelete(item);
    setIsItemDeleteModalOpen(true);
  };

  const handleConfirmItemDelete = async () => {
    if (!itemToDelete || !selectedProject) return;

    setIsDeleting(true);
    try {
      if (itemToDelete.type === "meme") {
        await generalApi.project.deleteMeme(
          selectedProject._id,
          itemToDelete._id
        );

        // Update selectedProject state by removing the deleted meme
        setSelectedProject((prev) => ({
          ...prev,
          meme: (prev.meme || []).filter((m) => m._id !== itemToDelete._id),
        }));
      } else {
        await generalApi.project.deleteArticle(
          selectedProject._id,
          itemToDelete._id
        );

        // Update selectedProject state by removing the deleted blog post
        setSelectedProject((prev) => ({
          ...prev,
          blogPosts: (prev.blogPosts || []).filter(
            (b) => b._id !== itemToDelete._id
          ),
        }));
      }

      toast.success(
        `${
          itemToDelete.type === "meme" ? "Meme" : "Article"
        } deleted successfully!`
      );
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error(
        `Failed to delete ${itemToDelete.type === "meme" ? "meme" : "article"}`
      );
    } finally {
      setIsDeleting(false);
      setIsItemDeleteModalOpen(false);
      setItemToDelete(null);
    }
  };

  return (
    <Container 
      maxWidth="xl" 
      sx={{ 
        py: 4,
        px: { xs: 2, sm: 3 },
        '&.MuiContainer-maxWidthXl': {
          maxWidth: '1440px'
        }
      }}
    >
      <AddProjectModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onProjectAdded={handleProjectAdded}
      />

      {selectedProject && (
        <EditProjectModal
          open={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedProject(null);
          }}
          project={selectedProject}
          onProjectUpdated={handleProjectUpdated}
        />
      )}

      <ConfirmationModal
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        title="Delete Project"
        message="Are you sure you want to delete this project? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
        severity="error"
      />

      <ConfirmationModal
        open={isItemDeleteModalOpen}
        onClose={() => {
          setIsItemDeleteModalOpen(false);
          setItemToDelete(null);
        }}
        onConfirm={handleConfirmItemDelete}
        title="Delete Item"
        message={
          <>
            <Typography gutterBottom>
              Are you sure you want to delete this{" "}
              {itemToDelete?.type === "meme" ? "meme" : "blog post"}?
            </Typography>
            <Box sx={{ mt: 2, p: 2, bgcolor: "grey.50", borderRadius: 1 }}>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                Type: {itemToDelete?.type === "meme" ? "Meme" : "Blog Post"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {itemToDelete?.type === "meme"
                  ? `Description: ${itemToDelete?.memeText || "No description"}`
                  : `Title: ${itemToDelete?.title || "Untitled"}`}
              </Typography>
            </Box>
            <Typography sx={{ mt: 2 }} color="error" variant="body2">
              This action cannot be undone!
            </Typography>
          </>
        }
        confirmText="Delete"
        cancelText="Cancel"
        severity="error"
        loading={isDeleting}
        disabled={isDeleting}
      />

      <Box sx={{ display: "flex" }}>
        {/* Sidebar */}
        <Paper
          sx={{
            width: 300,
            p: 2,
            height: "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px 0 0 4px",
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRight: "none",
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{ mb: 4, fontWeight: "bold" }}
          >
            Projects
          </Typography>

          <TextField
            fullWidth
            placeholder="Search projects..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <Search size={20} style={{ marginRight: 8, color: "#666" }} />
              ),
            }}
            sx={{ mb: 2 }}
          />

          <Button
            variant="contained"
            startIcon={<FolderPlus />}
            fullWidth
            onClick={() => setIsAddModalOpen(true)}
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
              textTransform: "none",
              mb: 2,
            }}
          >
            New Project
          </Button>

          <Divider sx={{ mb: 2 }} />

          {/* Project List */}
          <List sx={{ flexGrow: 1, overflow: "auto" }}>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress />
              </Box>
            ) : error ? (
              <Typography color="error" align="center" sx={{ p: 2 }}>
                {error}
              </Typography>
            ) : filteredProjects.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  color: "text.secondary",
                  textAlign: "center",
                  p: 2,
                }}
              >
                <Folder size={48} style={{ marginBottom: 16, opacity: 0.5 }} />
                <Typography variant="body2">
                  {searchTerm
                    ? "No matching projects found"
                    : "No projects yet. Create your first project to get started!"}
                </Typography>
              </Box>
            ) : (
              filteredProjects.map((project) => (
                <ListItem
                  key={project._id}
                  disablePadding
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={(e) => handleMenuOpen(e, project)}
                      sx={{ mr: 1 }}
                    >
                      <MoreVertical size={20} />
                    </IconButton>
                  }
                >
                  <ListItemButton
                    onClick={() => handleProjectSelect(project)}
                    selected={selectedProject?._id === project._id}
                  >
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <Folder size={20} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <ReactCountryFlag
                            countryCode={
                              getFlagInfo(project.language).countryCode
                            }
                            svg
                            style={{
                              width: "1.5em",
                              height: "1.5em",
                            }}
                          />
                          <Typography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "180px", // Adjust this value based on your needs
                            }}
                          >
                            {project.name}
                          </Typography>
                        </Box>
                      }
                      secondary={project.description}
                      secondaryTypographyProps={{
                        sx: {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: "1",
                          WebkitBoxOrient: "vertical",
                          lineHeight: "1.4em",
                          height: "1.4em",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))
            )}

            {/* More Options Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              onClick={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                  mt: 1,
                  "& .MuiMenuItem-root": {
                    px: 2,
                    py: 1,
                    gap: 1.5,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {[
                {
                  id: "edit",
                  label: "Edit",
                  icon: <Edit size={18} />,
                  onClick: handleEditClick,
                  color: "inherit",
                },
                {
                  id: "delete",
                  label: "Delete",
                  icon: <Trash2 size={18} />,
                  onClick: handleDeleteClick,
                  color: "error.main",
                },
              ].map((item) => (
                <MenuItem
                  key={item.id}
                  onClick={item.onClick}
                  sx={{ color: item.color }}
                >
                  <MenuItemIcon>{item.icon}</MenuItemIcon>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </List>
        </Paper>

        {/* Main Content */}
        <Paper
          sx={{
            flexGrow: 1,
            p: 4,
            height: "calc(100vh - 150px)",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0 4px 4px 0",
            boxShadow: "none",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {selectedProject ? (
            <Box sx={{ height: "100%", overflow: "auto" }}>
              <Box
                sx={{ display: "flex", alignItems: "center", mb: 3 }}
                title={selectedProject.description}
              >
                <Typography
                  variant="h6"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <ReactCountryFlag
                    countryCode={
                      getFlagInfo(selectedProject.language).countryCode
                    }
                    svg
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                    }}
                  />
                  {selectedProject.name}
                </Typography>
              </Box>

              <TableContainer
                component={TablePaper}
                sx={{
                  boxShadow: "none",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="project content table"
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                      <TableCell>Preview</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell sx={{ width: '300px' }}>Title/Description</TableCell>
                      <TableCell>Added Date</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Combine and sort memes and blog posts */}
                    {[
                      ...(selectedProject.meme || []).map((item) => ({
                        ...item,
                        type: "meme",
                      })),
                      ...(selectedProject.blogPosts || []).map((item) => ({
                        ...item,
                        type: "blog",
                      })),
                    ].sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ).length > 0 ? (
                      [
                        ...(selectedProject.meme || []).map((item) => ({
                          ...item,
                          type: "meme",
                        })),
                        ...(selectedProject.blogPosts || []).map((item) => ({
                          ...item,
                          type: "blog",
                        })),
                      ]
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((item) => (
                          <TableRow
                            key={item._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              {item.type === "meme" ? (
                                <Box
                                  sx={{
                                    width: 100,
                                    height: 60,
                                    position: "relative",
                                    borderRadius: 1,
                                    overflow: "hidden",
                                    bgcolor: "grey.100",
                                  }}
                                >
                                  {item.url ? (
                                    <img
                                      src={item.url}
                                      alt={item.description}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <ImageIcon size={24} />
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                // Blog post preview placeholder
                                <Box
                                  sx={{
                                    width: 100,
                                    height: 60,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "grey.100",
                                    borderRadius: 1,
                                  }}
                                >
                                  <Typography variant="caption">
                                    Blog Post
                                  </Typography>
                                </Box>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.type === "meme" ? "Meme" : "Blog Post"}
                            </TableCell>
                            <TableCell>
                              {item.type === "blog" ? (
                                <Link
                                  href={`/social-first-blog-posts/${item._id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    textDecoration: "none",
                                    color: "primary.main",
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '300px',
                                    wordWrap: 'break-word'
                                  }}
                                >
                                  {item.title || "Untitled"}
                                </Link>
                              ) : (
                                <Typography
                                  sx={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: '2',
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '300px',
                                    wordWrap: 'break-word'
                                  }}
                                >
                                  {item.memeText || "-"}
                                </Typography>
                              )} 
                            </TableCell>
                            <TableCell>
                              {formatDate(
                                item.createdAt || selectedProject.createdAt
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                onClick={() =>
                                  window.open(
                                    item.type === "meme"
                                      ? item.url
                                      : `/social-first-blog-posts/${item._id}`,
                                    "_blank"
                                  )
                                }
                                size="small"
                                sx={{ mr: 1 }}
                                title="View"
                              >
                                <Eye size={18} />
                              </IconButton>
                              {item.type === "meme" && (
                                <IconButton
                                  onClick={() => {
                                    /* Add download handler */
                                  }}
                                  size="small"
                                  sx={{ mr: 1 }}
                                  title="Download"
                                >
                                  <Download size={18} />
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => handleItemDeleteClick(item)}
                                size="small"
                                sx={{ color: "error.main" }}
                                title="Delete"
                              >
                                <Trash2 size={18} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center" sx={{ py: 4 }}>
                          <Box
                            sx={{
                              color: "text.secondary",
                              textAlign: "center",
                            }}
                          >
                            <Folder
                              size={32}
                              style={{ marginBottom: 8, opacity: 0.5 }}
                            />
                            <Typography variant="body2">
                              No content has been added to this project yet.
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              <Box>
                <Folder size={64} style={{ marginBottom: 16, opacity: 0.5 }} />
                <Typography variant="h6">
                  Select a project to view details
                </Typography>
              </Box>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default ProjectsScreen;
