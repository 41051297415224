import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  CircularProgress,
  Box,
} from "@mui/material";

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = "Confirm",
  cancelText = "Cancel",
  severity = "info",
  loading = false,
  disabled = false,
}) => {
  return (
    <Dialog open={open} onClose={disabled ? undefined : onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={disabled || loading}>
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          color={severity}
          variant="contained"
          disabled={disabled || loading}
          sx={{ position: "relative" }}
        >
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                left: "50%",
                marginLeft: "-12px",
              }}
            />
          )}
          <Box sx={{ visibility: loading ? "hidden" : "visible" }}>
            {confirmText}
          </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
