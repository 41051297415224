import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Container,
} from "@mui/material";
import {
  Image,
  Target,
  MessageSquare,
  Zap,
  Facebook,
  BookOpen,
  ExternalLink,
  MessageCircle,
  Lightbulb,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

const DashboardCard = ({
  icon: Icon,
  title,
  description,
  buttonText,
  onClick,
  disabled,
  comingSoon,
  comingSoonDate,
}) => (
  <Card
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "none",
      border: "1px solid rgba(0, 0, 0, 0.12)",
    }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: "24px",
      }}
    >
      <Icon size={24} style={{ color: "#9c27b0", marginBottom: "16px" }} />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      {comingSoon && (
        <Typography 
          variant="caption" 
          sx={{ 
            color: '#9c27b0', 
            fontWeight: 'bold',
            mb: 1,
            display: 'block'
          }}
        >
          Coming {comingSoonDate}
        </Typography>
      )}
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 3, flexGrow: 1 }}
      >
        {description}
      </Typography>
      <Button
        variant="contained"
        fullWidth
        onClick={onClick}
        disabled={disabled}
        sx={{
          bgcolor: "#9c27b0",
          "&:hover": { bgcolor: "#7b1fa2" },
          textTransform: "none",
          padding: "10px 0",
        }}
      >
        {buttonText}
      </Button>
    </CardContent>
  </Card>
);

const Dashboard = () => {
  const navigate = useNavigate();

  const tools = [
    {
      icon: Lightbulb,
      title: "Blog Post Ideas",
      description:
        "Get blog post ideas that will crush it on social media, helping you create content that resonates with your audience.",
      buttonText: "Generate Ideas",
      onClick: () => navigate('/blog-post-ideas'),
    },
    {
      icon: MessageSquare,
      title: "Social-First Blog Posts",
      description:
        "Generate one or multiple blog posts optimized for social sharing, increasing your content's reach and impact.",
      buttonText: "Create Posts",
      onClick: () => navigate('/social-first'),
    },
    {
      icon: Zap,
      title: "Status Post Generator",
      description:
        "Generate engaging social media posts that capture attention and drive interaction with your audience.",
      buttonText: "Create Status",
      onClick: () => navigate('/status-post-generator'),
    },
    {
      icon: Image,
      title: "Meme Generator",
      description:
        "Generate memes relevant to your niche to maximize engagement and boost your social media presence.",
      buttonText: "Create Meme",
      onClick: () => navigate('/meme-generator'),
    },
    {
      icon: Facebook,
      title: "Facebook Ad Creator",
      description:
        "Generate engaging Facebook ads with images that convert viewers into customers and boost your ROI.",
      buttonText: "Create Ad",
      onClick: () => navigate('/facebook-ad-creator'),
    },
    {
      icon: ExternalLink,
      title: "Traffic Driving Image Post",
      description:
        "Create images with text over to drive traffic to blog posts.",
      buttonText: "Create Image",
      onClick: () => navigate('/traffic-image'),
      disabled: true,
      opacity: 0.6,
      comingSoon: true,
      comingSoonDate: "December 11th",
    },
    {
      icon: BookOpen,
      title: "Niche Toolbox Training",
      description:
        "Access our video guides and training materials to master all the tools and maximize your content creation potential.",
      buttonText: "Start Learning",
      onClick: () => navigate('/training'),
      disabled: true,
      opacity: 0.6,
      comingSoon: true,
      comingSoonDate: "December 10th",
    },
    {
      icon: MessageCircle,
      title: "NSL Chat AI",
      description:
        "Our real-time chat will answer all your niche website or monetization questions, and help you with your niche website.",
      buttonText: "Launch Chat",
      onClick: () => navigate('/nsl-chat'),
      disabled: true,
      opacity: 0.6,
      comingSoon: true,
      comingSoonDate: "December 12th",
    },
  ];

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        py: 2,
        px: { xs: 2, sm: 2, md: 2 },
        maxWidth: '1800px',
        margin: '0 auto',
        overflow: 'hidden',
        height: '100%'
      }}
    >
      <Typography
        variant="h5"
        component="h1"
        sx={{ mb: "20px", mt: '20px' }}
      >
        Welcome to Niche Toolbox
      </Typography>
      <Grid 
        container 
        spacing={1.5}
        sx={{ 
          mx: 0,
          '& .MuiGrid-item': {
            pb: '0 !important'
          }
        }}
      >
        {tools.map((tool, index) => (
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            key={index}
            sx={{
              "& .MuiCard-root": {
                width: "100%",
                maxWidth: "100%",
                opacity: tool.opacity || 1,
                mb: index >= 4 ? 0 : 1
              },
            }}
          >
            <DashboardCard {...tool} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
