import React, { useEffect } from "react";
import { TextField, MenuItem, Box } from "@mui/material";
import ReactCountryFlag from "react-country-flag";

export const languages = [
  // English Variants
  { code: "en-US", name: "English (US)", countryCode: "US" },
  { code: "en-GB", name: "English (UK)", countryCode: "GB" },
  { code: "en-AU", name: "English (Australia)", countryCode: "AU" },
  { code: "en-CA", name: "English (Canada)", countryCode: "CA" },
  { code: "en-NZ", name: "English (New Zealand)", countryCode: "NZ" },
  { code: "en-IE", name: "English (Ireland)", countryCode: "IE" },
  { code: "en-ZA", name: "English (South Africa)", countryCode: "ZA" },

  // Spanish Variants
  { code: "es-ES", name: "Spanish (Spain)", countryCode: "ES" },
  { code: "es-MX", name: "Spanish (Mexico)", countryCode: "MX" },
  { code: "es-AR", name: "Spanish (Argentina)", countryCode: "AR" },
  { code: "es-CO", name: "Spanish (Colombia)", countryCode: "CO" },
  { code: "es-CL", name: "Spanish (Chile)", countryCode: "CL" },
  { code: "es-PE", name: "Spanish (Peru)", countryCode: "PE" },
  { code: "es-VE", name: "Spanish (Venezuela)", countryCode: "VE" },
  { code: "es-UY", name: "Spanish (Uruguay)", countryCode: "UY" },
  { code: "es-PY", name: "Spanish (Paraguay)", countryCode: "PY" },
  { code: "es-EC", name: "Spanish (Ecuador)", countryCode: "EC" },
  { code: "es-CR", name: "Spanish (Costa Rica)", countryCode: "CR" },
  { code: "es-DO", name: "Spanish (Dominican Republic)", countryCode: "DO" },
  { code: "es-GT", name: "Spanish (Guatemala)", countryCode: "GT" },
  { code: "es-PA", name: "Spanish (Panama)", countryCode: "PA" },
  { code: "es-SV", name: "Spanish (El Salvador)", countryCode: "SV" },
  { code: "es-HN", name: "Spanish (Honduras)", countryCode: "HN" },
  { code: "es-NI", name: "Spanish (Nicaragua)", countryCode: "NI" },
  { code: "es-BO", name: "Spanish (Bolivia)", countryCode: "BO" },
  { code: "es-PR", name: "Spanish (Puerto Rico)", countryCode: "PR" },

  // French Variants
  { code: "fr-FR", name: "French (France)", countryCode: "FR" },
  { code: "fr-CA", name: "French (Canada)", countryCode: "CA" },
  { code: "fr-BE", name: "French (Belgium)", countryCode: "BE" },
  { code: "fr-CH", name: "French (Switzerland)", countryCode: "CH" },
  { code: "fr-LU", name: "French (Luxembourg)", countryCode: "LU" },
  { code: "fr-MC", name: "French (Monaco)", countryCode: "MC" },

  // German Variants
  { code: "de-DE", name: "German (Germany)", countryCode: "DE" },
  { code: "de-AT", name: "German (Austria)", countryCode: "AT" },
  { code: "de-CH", name: "German (Switzerland)", countryCode: "CH" },

  // Chinese Variants
  { code: "zh-CN", name: "Chinese (Simplified)", countryCode: "CN" },
  { code: "zh-TW", name: "Chinese (Traditional, Taiwan)", countryCode: "TW" },
  { code: "zh-HK", name: "Chinese (Hong Kong)", countryCode: "HK" },
  { code: "zh-SG", name: "Chinese (Singapore)", countryCode: "SG" },
  { code: "zh-MO", name: "Chinese (Macau)", countryCode: "MO" },

  // Other Major Languages
  { code: "hi-IN", name: "Hindi", countryCode: "IN" },
  { code: "bn-IN", name: "Bengali", countryCode: "IN" },
  { code: "ja-JP", name: "Japanese", countryCode: "JP" },
  { code: "ko-KR", name: "Korean", countryCode: "KR" },
  { code: "ru-RU", name: "Russian", countryCode: "RU" },
  { code: "tr-TR", name: "Turkish", countryCode: "TR" },
  { code: "it-IT", name: "Italian", countryCode: "IT" },
  { code: "nl-NL", name: "Dutch", countryCode: "NL" },
  { code: "pl-PL", name: "Polish", countryCode: "PL" },
  { code: "vi-VN", name: "Vietnamese", countryCode: "VN" },
  { code: "th-TH", name: "Thai", countryCode: "TH" },
  { code: "id-ID", name: "Indonesian", countryCode: "ID" },
  { code: "ms-MY", name: "Malay", countryCode: "MY" },
  { code: "fil-PH", name: "Filipino", countryCode: "PH" },

  // Additional Languages
  { code: "sq-AL", name: "Albanian", countryCode: "AL" },
  { code: "be-BY", name: "Belarusian", countryCode: "BY" },
  { code: "bs-BA", name: "Bosnian", countryCode: "BA" },
  { code: "ny-MW", name: "Chichewa", countryCode: "MW" },
  { code: "dv-MV", name: "Divehi", countryCode: "MV" },
  { code: "dz-BT", name: "Dzongkha", countryCode: "BT" },
  { code: "fo-FO", name: "Faroese", countryCode: "FO" },
  { code: "ka-GE", name: "Georgian", countryCode: "GE" },
  { code: "el-GR", name: "Greek", countryCode: "GR" },
  { code: "gu-IN", name: "Gujarati", countryCode: "IN" },
  { code: "he-IL", name: "Hebrew", countryCode: "IL" },
  { code: "is-IS", name: "Icelandic", countryCode: "IS" },
  { code: "kn-IN", name: "Kannada", countryCode: "IN" },
  { code: "kk-KZ", name: "Kazakh", countryCode: "KZ" },
  { code: "km-KH", name: "Khmer", countryCode: "KH" },
  { code: "ky-KG", name: "Kyrgyz", countryCode: "KG" },
  { code: "lo-LA", name: "Lao", countryCode: "LA" },
  { code: "lv-LV", name: "Latvian", countryCode: "LV" },
  { code: "lt-LT", name: "Lithuanian", countryCode: "LT" },
  { code: "mk-MK", name: "Macedonian", countryCode: "MK" },
  { code: "mg-MG", name: "Malagasy", countryCode: "MG" },
  { code: "ml-IN", name: "Malayalam", countryCode: "IN" },
  { code: "mt-MT", name: "Maltese", countryCode: "MT" },
  { code: "mr-IN", name: "Marathi", countryCode: "IN" },
  { code: "mn-MN", name: "Mongolian", countryCode: "MN" },
  { code: "my-MM", name: "Myanmar (Burmese)", countryCode: "MM" },
  { code: "ne-NP", name: "Nepali", countryCode: "NP" },
  { code: "no-NO", name: "Norwegian", countryCode: "NO" },
  { code: "or-IN", name: "Odia", countryCode: "IN" },
  { code: "ps-AF", name: "Pashto", countryCode: "AF" },
  { code: "fa-IR", name: "Persian", countryCode: "IR" },
  { code: "pa-IN", name: "Punjabi", countryCode: "IN" },
  { code: "ro-RO", name: "Romanian", countryCode: "RO" },
  { code: "sr-RS", name: "Serbian", countryCode: "RS" },
  { code: "si-LK", name: "Sinhala", countryCode: "LK" },
  { code: "sk-SK", name: "Slovak", countryCode: "SK" },
  { code: "sl-SI", name: "Slovenian", countryCode: "SI" },
  { code: "so-SO", name: "Somali", countryCode: "SO" },
  { code: "sw-KE", name: "Swahili", countryCode: "KE" },
  { code: "sv-SE", name: "Swedish", countryCode: "SE" },
  { code: "tg-TJ", name: "Tajik", countryCode: "TJ" },
  { code: "ta-IN", name: "Tamil", countryCode: "IN" },
  { code: "te-IN", name: "Telugu", countryCode: "IN" },
  { code: "bo-CN", name: "Tibetan", countryCode: "CN" },
  { code: "ti-ET", name: "Tigrinya", countryCode: "ET" },
  { code: "tk-TM", name: "Turkmen", countryCode: "TM" },
  { code: "ur-PK", name: "Urdu", countryCode: "PK" },
  { code: "ug-CN", name: "Uyghur", countryCode: "CN" },
  { code: "uz-UZ", name: "Uzbek", countryCode: "UZ" },
];

const LanguageSelector = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
  sx,
}) => {
  useEffect(() => {
    // If no value is provided, try to get from localStorage
    if (!value) {
      const savedLanguage = localStorage.getItem("selectedLanguage");
      if (savedLanguage && onChange) {
        onChange({ target: { value: savedLanguage } });
      }
    }
  }, []);

  const handleLanguageChange = (event) => {
    // Save to localStorage when language changes
    localStorage.setItem("selectedLanguage", event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextField
      select
      fullWidth
      label="Language"
      value={value}
      onChange={handleLanguageChange}
      error={error}
      helperText={helperText}
      disabled={disabled}
      sx={sx}
      // Show flag in the main TextField display
      SelectProps={{
        renderValue: (selected) => {
          const lang = languages.find((l) => l.code === selected);
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <ReactCountryFlag
                countryCode={lang?.countryCode}
                svg
                style={{
                  width: "1.5em",
                  height: "1.5em",
                }}
              />
              <span>{lang?.name}</span>
            </Box>
          );
        },
      }}
    >
      {languages.map((language) => (
        <MenuItem
          key={language.code}
          value={language.code}
          sx={{ minHeight: "40px" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ReactCountryFlag
              countryCode={language.countryCode}
              svg
              style={{
                width: "1.5em",
                height: "1.5em",
              }}
            />
            <span>{language.name}</span>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
};
export default LanguageSelector;
