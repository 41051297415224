import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

const BrowseMemeCanvas = forwardRef((props, ref) => {
  const {
    preview,
    memeText,
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    onTextPositionChange,
    isTemplate = false,
    templateData = null,
    selectedTextIndex,
    onTextSelect,
  } = props;

  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });
  const [isResizing, setIsResizing] = useState(false);
  const [resizeStart, setResizeStart] = useState({ x: 0, y: 0 });
  const [resizeHandle, setResizeHandle] = useState(null);
  const HANDLE_SIZE = 8;

  useEffect(() => {
    if (preview && !imageRef.current) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = preview;
      img.onload = () => {
        imageRef.current = img;
        setOriginalDimensions({ width: img.width, height: img.height });
        drawCanvas();
      };
    } else if (imageRef.current) {
      drawCanvas();
    }
  }, [
    preview,
    memeText,
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    templateData,
    selectedTextIndex
  ]);

  const screenToImageCoordinates = (screenX, screenY, canvas) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    
    return {
      x: (screenX - rect.left) * scaleX,
      y: (screenY - rect.top) * scaleY
    };
  };

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const { x, y } = screenToImageCoordinates(e.clientX, e.clientY, canvas);

    if (isTemplate && templateData) {
      templateData.coordinates.forEach((coord, index) => {
        // Check for resize handles first if text is selected
        if (index === selectedTextIndex) {
          const handle = getResizeHandle(x, y, coord);
          if (handle) {
            setIsResizing(true);
            setResizeHandle(handle);
            setResizeStart({ x, y });
            e.preventDefault(); // Prevent text selection
            return;
          }
        }

        // Check for text selection
        if (isClickNearText(x, y, coord)) {
          setIsDragging(true);
          setDragStart({ x, y });
          onTextSelect(index);
          e.preventDefault();
        }
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging && !isResizing) return;

    const canvas = canvasRef.current;
    const { x, y } = screenToImageCoordinates(e.clientX, e.clientY, canvas);

    if (isResizing && selectedTextIndex !== null) {
      const dx = x - resizeStart.x;
      const dy = y - resizeStart.y;
      const newCoordinates = [...templateData.coordinates];
      const coord = newCoordinates[selectedTextIndex];

      switch (resizeHandle) {
        case 'nw':
          coord.width -= dx;
          coord.height -= dy;
          coord.x += dx;
          coord.y += dy;
          break;
        case 'ne':
          coord.width += dx;
          coord.height -= dy;
          coord.y += dy;
          break;
        case 'se':
          coord.width += dx;
          coord.height += dy;
          break;
        case 'sw':
          coord.width -= dx;
          coord.height += dy;
          coord.x += dx;
          break;
      }

      // Ensure minimum size
      coord.width = Math.max(50, coord.width);
      coord.height = Math.max(20, coord.height);

      onTextPositionChange({ coordinates: newCoordinates });
      setResizeStart({ x, y });
    } else if (isDragging && selectedTextIndex !== null) {
      const dx = x - dragStart.x;
      const dy = y - dragStart.y;

      const newCoordinates = [...templateData.coordinates];
      newCoordinates[selectedTextIndex] = {
        ...newCoordinates[selectedTextIndex],
        x: newCoordinates[selectedTextIndex].x + dx,
        y: newCoordinates[selectedTextIndex].y + dy,
      };
      onTextPositionChange({ coordinates: newCoordinates });
      setDragStart({ x, y });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
    setResizeHandle(null);
  };

  const getTemplateBounds = (coord) => {
    return {
      x: coord.x,
      y: coord.y,
      width: coord.width,
      height: coord.height,
    };
  };

  const getTextBounds = () => {
    if (!isTemplate) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const scaledFontSize = (fontSize * canvas.width) / 1000;
      ctx.font = `${scaledFontSize}px ${selectedFont}`;
      const metrics = ctx.measureText(memeText);

      return {
        x: textPosition.x - metrics.width / 2,
        y: textPosition.y - scaledFontSize / 2,
        width: metrics.width,
        height: scaledFontSize,
      };
    }
    return null;
  };

  const isClickNearText = (x, y, coord) => {
    return (
      x >= coord.x &&
      x <= coord.x + coord.width &&
      y >= coord.y &&
      y <= coord.y + coord.height
    );
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = imageRef.current;

    if (!img) return;

    // Set canvas dimensions
    canvas.width = originalDimensions.width || img.width;
    canvas.height = originalDimensions.height || img.height;

    // Scale display
    canvas.style.width = '100%';
    canvas.style.height = 'auto';

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    if (isTemplate && templateData && templateData.textFields) {
      Object.entries(templateData.textFields).forEach(([key, text], index) => {
        if (!templateData.coordinates[index]) return;

        drawTemplateText(
          ctx,
          text,
          templateData.coordinates[index],
          index,
          templateData.textType === "white"
        );
      });
    } else if (!isTemplate && memeText && textPosition) {
      drawSingleText(ctx);
    }
  };

  const drawTemplateText = (ctx, text, coord, index, isWhiteText, skipSelection = false) => {
    if (!text || !coord) return;

    ctx.save();

    const textSettings = templateData.textSettings[`text${index + 1}`] || {
      fontSize: 50,
      textAreaWidth: 100,
    };
    const { globalSettings } = templateData;

    // Start with a large font size and reduce until text fits
    let fontSize = coord.height * (textSettings.fontSize / 100);
    const maxWidth = coord.width * (textSettings.textAreaWidth / 100);
    
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    
    // Calculate the best font size that fits both width and height
    let lines = [];
    while (fontSize > 1) {
      ctx.font = `${fontSize}px ${globalSettings.selectedFont}`;
      lines = [];
      let currentLine = '';
      const words = text.split(' ');
      
      // Test line wrapping with current font size
      for (const word of words) {
        const testLine = currentLine ? `${currentLine} ${word}` : word;
        const metrics = ctx.measureText(testLine);
        
        if (metrics.width > maxWidth) {
          lines.push(currentLine);
          currentLine = word;
        } else {
          currentLine = testLine;
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }

      // Check if text fits in height
      const lineHeight = fontSize * 1.2;
      const totalTextHeight = lines.length * lineHeight;
      
      if (totalTextHeight <= coord.height * 0.9) { // Leave 10% margin
        break;
      }
      
      fontSize *= 0.9; // Reduce font size by 10% and try again
    }

    // Apply final settings
    ctx.font = `${fontSize}px ${globalSettings.selectedFont}`;

    // Set text rendering based on textType
    if (isWhiteText) {
      // White text with black outline (original behavior)
      ctx.strokeStyle = globalSettings.textStrokeColor;
      ctx.fillStyle = globalSettings.textFillColor;
      ctx.lineWidth = fontSize * (globalSettings.strokeWidth / 100);
      ctx.lineJoin = 'round';

      // Draw outline first
      const lineHeight = fontSize * 1.2;
      const totalHeight = lines.length * lineHeight;
      let currentY = coord.y + (coord.height - totalHeight) / 2 + fontSize / 2;

      lines.forEach(line => {
        ctx.strokeText(line, coord.x + coord.width / 2, currentY, maxWidth);
        currentY += lineHeight;
      });

      // Reset Y position for fill
      currentY = coord.y + (coord.height - totalHeight) / 2 + fontSize / 2;

      // Then draw fill
      lines.forEach(line => {
        ctx.fillText(line, coord.x + coord.width / 2, currentY, maxWidth);
        currentY += lineHeight;
      });
    } else {
      // Black text without outline
      ctx.fillStyle = '#000000';
      
      const lineHeight = fontSize * 1.2;
      const totalHeight = lines.length * lineHeight;
      let currentY = coord.y + (coord.height - totalHeight) / 2 + fontSize / 2;

      // Draw text without outline
      lines.forEach(line => {
        ctx.fillText(line, coord.x + coord.width / 2, currentY, maxWidth);
        currentY += lineHeight;
      });
    }

    // Only draw selection if not skipping and text is selected
    if (!skipSelection && index === selectedTextIndex) {
      ctx.strokeStyle = '#9c27b0';
      ctx.lineWidth = 2;
      ctx.strokeRect(coord.x, coord.y, coord.width, coord.height);
      drawResizeHandles(ctx, coord);
    }

    ctx.restore();
  };

  const fitTextInArea = (ctx, text, coord, fontSize, maxWidth) => {
    const words = text.split(' ');
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const testLine = currentLine + " " + words[i];
      const metrics = ctx.measureText(testLine);

      if (metrics.width > maxWidth) {
        lines.push(currentLine);
        currentLine = words[i];
      } else {
        currentLine = testLine;
      }
    }
    lines.push(currentLine);

    const lineHeight = fontSize * 1.2;
    return { lines, lineHeight };
  };

  const drawSingleText = (ctx) => {
    if (!isTemplate) {
      ctx.save();
      const canvas = canvasRef.current;
      const scaledFontSize = (fontSize * canvas.width) / 1000;
      ctx.font = `${scaledFontSize}px ${selectedFont}`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      const maxWidth = (canvas.width * textAreaWidth) / 100;
      const words = memeText.split(" ");
      let lines = [];
      let currentLine = words[0];

      for (let i = 1; i < words.length; i++) {
        const testLine = currentLine + " " + words[i];
        const metrics = ctx.measureText(testLine);

        if (metrics.width > maxWidth) {
          lines.push(currentLine);
          currentLine = words[i];
        } else {
          currentLine = testLine;
        }
      }
      lines.push(currentLine);

      const scaledStrokeWidth = (strokeWidth * canvas.width) / 1000;

      ctx.strokeStyle = textStrokeColor;
      ctx.lineWidth = scaledStrokeWidth;
      ctx.lineJoin = "round";
      lines.forEach((line, index) => {
        const y = textPosition.y + (index - (lines.length - 1) / 2) * scaledFontSize;
        ctx.strokeText(line, textPosition.x, y, maxWidth);
      });

      ctx.fillStyle = textFillColor;
      lines.forEach((line, index) => {
        const y = textPosition.y + (index - (lines.length - 1) / 2) * scaledFontSize;
        ctx.fillText(line, textPosition.x, y, maxWidth);
      });

      ctx.restore();
    }
  };

  const drawResizeHandles = (ctx, coord) => {
    if (selectedTextIndex === null) return;
    
    const canvas = canvasRef.current;
    const handleSize = HANDLE_SIZE * (canvas.width / canvas.getBoundingClientRect().width);
    
    const handles = {
      'nw': { x: coord.x, y: coord.y },
      'ne': { x: coord.x + coord.width, y: coord.y },
      'se': { x: coord.x + coord.width, y: coord.y + coord.height },
      'sw': { x: coord.x, y: coord.y + coord.height }
    };

    ctx.fillStyle = '#9c27b0';
    Object.entries(handles).forEach(([position, point]) => {
      ctx.fillRect(
        point.x - handleSize/2,
        point.y - handleSize/2,
        handleSize,
        handleSize
      );
    });
  };

  const getResizeHandle = (x, y, coord) => {
    const canvas = canvasRef.current;
    const handleSize = HANDLE_SIZE * (canvas.width / canvas.getBoundingClientRect().width);
    
    const handles = {
      'nw': { x: coord.x, y: coord.y },
      'ne': { x: coord.x + coord.width, y: coord.y },
      'se': { x: coord.x + coord.width, y: coord.y + coord.height },
      'sw': { x: coord.x, y: coord.y + coord.height }
    };

    for (const [position, point] of Object.entries(handles)) {
      if (
        x >= point.x - handleSize &&
        x <= point.x + handleSize &&
        y >= point.y - handleSize &&
        y <= point.y + handleSize
      ) {
        return position;
      }
    }
    return null;
  };

  // Add new function for drawing without selection elements
  const drawCanvasForDownload = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = imageRef.current;

    if (!img) return;

    // Set canvas dimensions
    canvas.width = originalDimensions.width || img.width;
    canvas.height = originalDimensions.height || img.height;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    if (isTemplate && templateData && templateData.textFields) {
      Object.entries(templateData.textFields).forEach(([key, text], index) => {
        if (!templateData.coordinates[index]) return;

        // Use drawTemplateText but force skipSelection to true
        drawTemplateText(
          ctx,
          text,
          templateData.coordinates[index],
          index,
          templateData.textType === "white",
          true // Force skip selection
        );
      });
    } else if (!isTemplate && memeText && textPosition) {
      drawSingleText(ctx);
    }
  };

  // Expose drawCanvasForDownload and canvas through ref
  React.useImperativeHandle(ref, () => ({
    drawCanvasForDownload,
    getCanvas: () => canvasRef.current,
    ...canvasRef.current
  }));

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <canvas
        ref={(el) => {
          canvasRef.current = el;
          if (typeof ref === "function") ref(el);
          else if (ref) ref.current = el;
        }}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "auto",
          height: "auto",
          objectFit: "contain",
          cursor: isResizing ? 'nwse-resize' : 
                  isDragging ? "grabbing" : 
                  "grab",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      />
    </Box>
  );
});

BrowseMemeCanvas.displayName = "BrowseMemeCanvas";

export default BrowseMemeCanvas;
