import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  styled,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import profileApi from "../api/profileApi";

const SettingsSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(2),
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.divider}`,
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: "0.875rem",
}));

function Settings() {
  const [openAIKey, setOpenAIKey] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTestKey = async () => {
    if (!openAIKey) {
      toast.error("Please enter an API key to test");
      return;
    }

    setIsSubmitting(true);
    try {
      const testResponse = await profileApi.testOpenAIKey(openAIKey);
      
      if (testResponse.error) {
        throw new Error(testResponse.message);
      }
      
      const updateResponse = await profileApi.updateOpenAIKey(openAIKey);
      if (updateResponse.error) {
        throw new Error(updateResponse.message);
      }
      toast.success("OpenAI API key was tested and saved successfully!");
      setOpenAIKey("");
      
    } catch (error) {
      toast.error(
        error.message || 
        "There was an error with your API key. Please check our troubleshooting guide: " +
        "niche.sh/how-to-get-gpt4-api-keys/"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClearKey = async () => {
    setIsSubmitting(true);
    try {
      const updateResponse = await profileApi.updateOpenAIKey("");
      if (updateResponse.error) {
        throw new Error(updateResponse.message);
      }
      toast.success("OpenAI API key was removed successfully!");
      setOpenAIKey("");
    } catch (error) {
      toast.error(error.message || "Failed to remove API key");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        margin: "auto",
        maxWidth: 1200,
        width: "100%",
        pt: { xs: "24px", sm: "54px" },
        pb: { xs: "24px", sm: "54px" },
        pl: { xs: "16px", sm: "24px" },
        pr: { xs: "16px", sm: "24px" },
      }}
    >
      <SettingsSection elevation={0}>
        <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
          <Grid item xs>
            <Typography variant="h4" gutterBottom>
              Settings
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Manage your API keys and other settings
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom fontWeight="600">
                API Keys
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InfoLabel>OpenAI API Key</InfoLabel>
                  <TextField
                    fullWidth
                    value={openAIKey}
                    onChange={(e) => setOpenAIKey(e.target.value)}
                    type="password"
                    placeholder="sk-..."
                    variant="outlined"
                    size="small"
                    sx={{ mt: 1 }}
                    helperText="Your OpenAI API key will be encrypted and stored securely"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleTestKey}
                      disabled={isSubmitting || !openAIKey}
                    >
                      {isSubmitting ? "Testing..." : "Test & Save Key"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleClearKey}
                      disabled={isSubmitting}
                    >
                      Clear Key
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom fontWeight="600">
                API Key Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InfoLabel>How to get an OpenAI API key</InfoLabel>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Visit our guide at{" "}
                    <a 
                      href="https://niche.sh/how-to-get-gpt4-api-keys/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ color: '#5c016f' }}
                    >
                      niche.sh/how-to-get-gpt4-api-keys
                    </a>
                    {" "}to learn how to obtain and set up your OpenAI API key.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </SettingsSection>
    </Box>
  );
}

export default Settings; 