import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
  Divider,
  Paper,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import generalApi from "../../api/generalApi";
import AddProjectModal from "./AddProjectModal";

const ProjectSelector = ({ open, onClose, onProjectSelect, isUploading }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  useEffect(() => {
    if (open) {
      fetchProjects();
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setSelectedProject(null);
    }
  }, [open]);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await generalApi.project.getProjectsLists();
      setProjects(response.results || []);
    } catch (err) {
      setError("Failed to load projects");
    } finally {
      setLoading(false);
    }
  };

  const handleAddToProject = () => {
    if (selectedProject) {
      onProjectSelect(selectedProject);
    }
  };

  const handleClose = () => {
    if (!isUploading) {
      setSelectedProject(null);
      onClose();
    }
  };

  const handleProjectAdded = async (newProject) => {
    await fetchProjects();
    setIsAddModalOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Project</DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 4 }}>
          <Autocomplete
            options={projects}
            getOptionLabel={(option) => option?.name || ""}
            onChange={(_, value) => setSelectedProject(value)}
            value={selectedProject}
            loading={loading}
            isOptionEqualToValue={(option, value) => option?._id === value?._id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Project"
                placeholder="Search projects..."
                error={!!error}
                helperText={error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setIsAddModalOpen(true)}
            sx={{ color: "#9c27b0", mr: "auto" }}
          >
            New Project
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddToProject}
            variant="contained"
            disabled={!selectedProject || isUploading}
            sx={{ bgcolor: "#9c27b0", "&:hover": { bgcolor: "#7b1fa2" } }}
          >
            {isUploading ? "Adding..." : "Add to Project"}
          </Button>
        </DialogActions>
      </Dialog>

      <AddProjectModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onProjectAdded={handleProjectAdded}
      />
    </>
  );
};

export default ProjectSelector;
