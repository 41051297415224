import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";

const MemeUploadCanvas = forwardRef((props, ref) => {
  const {
    preview,
    memeText,
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    onTextPositionChange,
    isTemplate = false,
    templateData = null,
  } = props;

  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [currentTextIndex, setCurrentTextIndex] = useState(null);
  const [originalDimensions, setOriginalDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (preview && !imageRef.current) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = preview;
      img.onload = () => {
        imageRef.current = img;
        setOriginalDimensions({ width: img.width, height: img.height });
        drawCanvas();
      };
    } else if (imageRef.current) {
      drawCanvas();
    }
  }, [
    preview,
    memeText,
    textPosition,
    fontSize,
    textAreaWidth,
    strokeWidth,
    textFillColor,
    textStrokeColor,
    selectedFont,
    templateData,
  ]);

  const screenToImageCoordinates = (screenX, screenY, canvas) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = originalDimensions.width / rect.width;
    const scaleY = originalDimensions.height / rect.height;
    
    return {
      x: (screenX - rect.left) * scaleX,
      y: (screenY - rect.top) * scaleY
    };
  };

  const handleMouseDown = (e) => {
    if (!isTemplate) {
      const canvas = canvasRef.current;
      const { x, y } = screenToImageCoordinates(e.clientX, e.clientY, canvas);
      const textBounds = getTextBounds();
      
      if (isClickNearText(x, y, textBounds)) {
        setIsDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
      }
    } else if (templateData) {
      const canvas = canvasRef.current;
      const { x, y } = screenToImageCoordinates(e.clientX, e.clientY, canvas);

      templateData.coordinates.forEach((coord, index) => {
        const bounds = getTemplateBounds(coord);
        if (isClickNearText(x, y, bounds)) {
          setIsDragging(true);
          setDragStart({ x: e.clientX, y: e.clientY });
          setCurrentTextIndex(index);
        }
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const canvas = canvasRef.current;
    const { x, y } = screenToImageCoordinates(e.clientX, e.clientY, canvas);
    const startPos = screenToImageCoordinates(dragStart.x, dragStart.y, canvas);

    const dx = x - startPos.x;
    const dy = y - startPos.y;

    if (isTemplate && templateData && currentTextIndex !== null) {
      const newCoordinates = [...templateData.coordinates];
      newCoordinates[currentTextIndex] = {
        ...newCoordinates[currentTextIndex],
        x: newCoordinates[currentTextIndex].x + dx,
        y: newCoordinates[currentTextIndex].y + dy,
      };
      onTextPositionChange({ coordinates: newCoordinates });
    } else {
      const newPosition = {
        x: textPosition.x + dx,
        y: textPosition.y + dy,
      };
      onTextPositionChange(newPosition);
    }
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setCurrentTextIndex(null);
  };

  const getTemplateBounds = (coord) => {
    return {
      x: coord.x,
      y: coord.y,
      width: coord.width,
      height: coord.height,
    };
  };

  const getTextBounds = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const scaledFontSize = (fontSize * canvas.width) / 1000;
    ctx.font = `${scaledFontSize}px ${selectedFont}`;
    const metrics = ctx.measureText(memeText);

    return {
      x: textPosition.x - metrics.width / 2,
      y: textPosition.y - scaledFontSize / 2,
      width: metrics.width,
      height: scaledFontSize,
    };
  };

  const isClickNearText = (x, y, bounds) => {
    const padding = 20;
    return (
      x >= bounds.x - padding &&
      x <= bounds.x + bounds.width + padding &&
      y >= bounds.y - padding &&
      y <= bounds.y + bounds.height + padding
    );
  };

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = imageRef.current;

    if (!img) return;

    // Set canvas to original image dimensions
    canvas.width = originalDimensions.width || img.width;
    canvas.height = originalDimensions.height || img.height;

    // Use CSS to scale the display
    canvas.style.width = '100%';
    canvas.style.height = 'auto';

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Debug log
    console.log('Drawing canvas with data:', {
      isTemplate,
      templateData,
      dimensions: { width: canvas.width, height: canvas.height },
      textFields: templateData?.textFields
    });

    if (isTemplate && templateData && templateData.textFields) {
      Object.entries(templateData.textFields).forEach(([key, text], index) => {
        if (!templateData.coordinates[index]) return;

        console.log("Drawing text:", {
          key,
          text,
          coord: templateData.coordinates[index],
        });

        drawTemplateText(
          ctx,
          text,
          templateData.coordinates[index],
          templateData.textType === "white"
        );
      });
    } else if (memeText && textPosition) {
      // Draw original single text
      drawSingleText(ctx);
    }
  };

  const drawTemplateText = (ctx, text, coord, isWhiteText) => {
    if (!text || !coord) return;

    ctx.save();

    // Use coordinates directly without scaling since we're using original dimensions
    const scaledCoord = coord;

    // Initial font size calculation
    let fontSize = Math.min(scaledCoord.height * 0.8, scaledCoord.width * 0.2);
    ctx.font = `${isWhiteText ? 'bold' : '600'} ${fontSize}px ${selectedFont}`;

    // Get text lines
    const { lines, finalFontSize, lineHeight } = fitTextInArea(ctx, text, scaledCoord, fontSize);

    // Set final font
    ctx.font = `${isWhiteText ? 'bold' : '600'} ${finalFontSize}px ${selectedFont}`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Calculate vertical center
    const totalTextHeight = lines.length * lineHeight;
    let currentY = scaledCoord.y + (scaledCoord.height - totalTextHeight) / 2 + finalFontSize / 2;

    // Draw outline for white text
    if (isWhiteText) {
      ctx.strokeStyle = textStrokeColor;
      ctx.lineWidth = finalFontSize * 0.05;
      ctx.lineJoin = 'round';
      
      lines.forEach(line => {
        ctx.strokeText(line, scaledCoord.x + scaledCoord.width / 2, currentY);
        currentY += lineHeight;
      });

      // Reset Y position for fill
      currentY = scaledCoord.y + (scaledCoord.height - totalTextHeight) / 2 + finalFontSize / 2;
    }

    // Draw fill
    ctx.fillStyle = isWhiteText ? textFillColor : textStrokeColor;
    lines.forEach(line => {
      ctx.fillText(line, scaledCoord.x + scaledCoord.width / 2, currentY);
      currentY += lineHeight;
    });

    ctx.restore();
  };

  const fitTextInArea = (ctx, text, coord, initialFontSize) => {
    let fontSize = initialFontSize;
    let lines = [];
    
    while (fontSize > 1) {
      ctx.font = `${fontSize}px ${selectedFont}`;
      lines = [];
      let currentLine = '';
      const words = text.split(' ');
      
      for (const word of words) {
        const testLine = currentLine ? `${currentLine} ${word}` : word;
        const metrics = ctx.measureText(testLine);
        
        if (metrics.width > coord.width * 0.9) {
          if (currentLine) lines.push(currentLine);
          currentLine = word;
        } else {
          currentLine = testLine;
        }
      }
      if (currentLine) lines.push(currentLine);
      
      const lineHeight = fontSize * 1.2;
      const totalHeight = lines.length * lineHeight;
      
      if (totalHeight <= coord.height * 0.9) {
        return { lines, finalFontSize: fontSize, lineHeight };
      }
      
      fontSize *= 0.9;
    }
    
    return { lines, finalFontSize: fontSize, lineHeight: fontSize * 1.2 };
  };

  const drawSingleText = (ctx) => {
    // Original single text drawing logic
    ctx.save();

    const canvas = canvasRef.current;
    const scaledFontSize = (fontSize * canvas.width) / 1000;
    ctx.font = `${scaledFontSize}px ${selectedFont}`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    const maxWidth = (canvas.width * textAreaWidth) / 100;
    const words = memeText.split(" ");
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
      const testLine = currentLine + " " + words[i];
      const metrics = ctx.measureText(testLine);

      if (metrics.width > maxWidth) {
        lines.push(currentLine);
        currentLine = words[i];
      } else {
        currentLine = testLine;
      }
    }
    lines.push(currentLine);

    const scaledStrokeWidth = (strokeWidth * canvas.width) / 1000;

    ctx.strokeStyle = textStrokeColor;
    ctx.lineWidth = scaledStrokeWidth;
    ctx.lineJoin = "round";
    lines.forEach((line, index) => {
      const y =
        textPosition.y + (index - (lines.length - 1) / 2) * scaledFontSize;
      ctx.strokeText(line, textPosition.x, y, maxWidth);
    });

    ctx.fillStyle = textFillColor;
    lines.forEach((line, index) => {
      const y =
        textPosition.y + (index - (lines.length - 1) / 2) * scaledFontSize;
      ctx.fillText(line, textPosition.x, y, maxWidth);
    });

    ctx.restore();
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <canvas
        ref={(el) => {
          canvasRef.current = el;
          if (typeof ref === "function") ref(el);
          else if (ref) ref.current = el;
        }}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "auto",
          height: "auto",
          objectFit: "contain",
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      />
    </Box>
  );
});

MemeUploadCanvas.displayName = "MemeUploadCanvas";

export default MemeUploadCanvas;