// src/theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#790194",
    },
    secondary: {
      main: "#65A30D",
    },
    background: {
      default: "#F2F3F5",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
                // @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
                
                body {
                    backgroundColor: '#ffffff';
                }

                /* Custom scrollbar styling */
                ::-webkit-scrollbar {
                    width: 12px !important;
                    height: 12px !important;
                }

                ::-webkit-scrollbar-track {
                    background: #f5f5f5;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: #E2E8F0;
                    border-radius: 10px;
                    border: 3px solid #f5f5f5;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background-color: #CBD5E1;
                }
            `,
    },
  },
});

export default theme;
