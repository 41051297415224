import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  styled,
  CircularProgress,
  Avatar,
  Divider,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import profileApi from "../api/profileApi";
import { useErrorHandler } from "../hooks/useErrorHandler";

const ProfileSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.spacing(2),
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.divider}`,
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 500,
  fontSize: "0.875rem",
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: "1rem",
}));

function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await profileApi.getProfile();
        setProfileData(data);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [handleError]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profileData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">
          Failed to load profile data. Please try again.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
        margin: "auto",
        pt: "54px !important",
      }}
    >
      <ProfileSection elevation={0}>
        <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
          <Grid item>
            <Avatar
              sx={{
                width: 100,
                height: 100,
                bgcolor: (theme) => theme.palette.primary.main,
              }}
            >
              {profileData?.name?.charAt(0)}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography variant="h4" gutterBottom>
              {profileData?.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {profileData?.email}
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom fontWeight="600">
                Personal Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InfoLabel>Full Name</InfoLabel>
                  <InfoValue>{profileData?.name}</InfoValue>
                </Grid>
                <Grid item xs={12}>
                  <InfoLabel>Email Address</InfoLabel>
                  <InfoValue>{profileData?.email}</InfoValue>
                </Grid>
                {/* Add more fields as needed */}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom fontWeight="600">
                Account Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    component={RouterLink}
                    to="/reset-password"
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    Change Password
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    component="a"
                    href="https://niche.sh/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                    fullWidth
                  >
                    Request Account Deletion
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </ProfileSection>
    </Box>
  );
}

export default Profile;
