// src/config/env.js

const dev = {
  API_URL: "http://localhost:3001/api",
  WP_BILLING_URL: "https://billing.niche.sh/",
  SOCKET_BE_URL: "http://localhost:3001",
};

const prod = {
  API_URL: "https://app-be.niche.sh/api",
  WP_BILLING_URL: "https://billing.niche.sh/",
  SOCKET_BE_URL: "https://app-be.niche.sh/api",
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

export default config;
