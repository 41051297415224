import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Button,
  Container,
  Divider,
} from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import logo from "../assets/mainLogo.png";

const AuthLayout = ({ children, isAuthenticated }) => {
  const location = useLocation();
  const isResetPage = ["/reset", "/reset-password"].includes(location.pathname);
  const isParasitePage = location.pathname.startsWith("/parasite");
  const showTopBar = !isAuthenticated || !isResetPage;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {showTopBar && (
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            {isParasitePage && (
              <Box sx={{ flexGrow: 1 }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ height: "auto", width: "100px" }}
                />
              </Box>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button color="inherit" component={RouterLink} to="/login">
                Login
              </Button>
              <Divider orientation="vertical" flexItem sx={{ mx: 1, my: 1 }} />
              <Button
                color="inherit"
                component={RouterLink}
                to="https://niche.sh"
                target="_blank"
              >
                Sign Up
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          mt: 8,
          mb: 2,
          textAlign: isParasitePage ? "inherit" : "center",
        }}
      >
        {!isParasitePage && (
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "100px",
              marginBottom: "2rem",
              marginRight: "-3px",
            }}
          />
        )}
        {children}
      </Container>
    </Box>
  );
};

export default AuthLayout;
