import React from "react";
import {
  Box,
  Typography,
  TextField,
  Slider,
  FormControl,
  Select,
  MenuItem,
  Button,
  Stack,
  CircularProgress,
  TextareaAutosize,
} from "@mui/material";
import { Download, PlusCircle, Wand2 } from "lucide-react";

const MemeBrowseCanvas = ({
  textFields,
  textSettings,
  globalSettings,
  selectedTextIndex,
  onTextSelect,
  onTextSettingChange,
  onGlobalSettingChange,
  fontOptions,
  onTextContentChange,
  userInput,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* Original User Input Display */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500, paddingTop: '20px' }}>
          Your Input
        </Typography>
        <TextField
          fullWidth
          multiline
          value={userInput || ""}
          onChange={(e) => {
            // Update the formData description in the parent component
            if (onTextContentChange) {
              onTextContentChange('description', e.target.value);
            }
          }}
          sx={{ 
            bgcolor: 'rgba(0, 0, 0, 0.03)',
            '& .MuiOutlinedInput-root': {
              borderRadius: 1,
            }
          }}
        />
      </Box>

      {/* Generated Text Fields */}
      <Box sx={{ mb: 4, pt: '10px' }}>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          Generated Text Fields
        </Typography>
        {Object.entries(textFields || {}).map(([key, value], index) => (
          <Box
            key={key}
            onClick={() => onTextSelect(index)}
            sx={{
              p: 2,
              mb: 1,
              border: '1px solid',
              borderColor: selectedTextIndex === index ? '#9c27b0' : '#e0e0e0',
              borderRadius: 1,
              cursor: 'pointer',
              bgcolor: selectedTextIndex === index ? 'rgba(156, 39, 176, 0.08)' : 'transparent',
              '&:hover': {
                bgcolor: selectedTextIndex === index ? 'rgba(156, 39, 176, 0.08)' : 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <TextField
              fullWidth
              multiline
              value={value}
              onChange={(e) => onTextContentChange(key, e.target.value)}
              onClick={(e) => {
                e.stopPropagation();
                onTextSelect(index);
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'transparent',
                  '& fieldset': {
                    border: 'none'
                  }
                }
              }}
            />
          </Box>
        ))}
      </Box>

      {/* Individual Settings (only shown when text is selected) */}
      {selectedTextIndex !== null && textSettings[`text${selectedTextIndex + 1}`] && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
            Text Settings
          </Typography>
          
          {/* Font Size */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
              Font Size: {textSettings[`text${selectedTextIndex + 1}`].fontSize}%
            </Typography>
            <Slider
              value={textSettings[`text${selectedTextIndex + 1}`].fontSize}
              onChange={(_, value) => onTextSettingChange(`text${selectedTextIndex + 1}`, 'fontSize', value)}
              min={5}
              max={100}
              sx={{
                color: "#1976d2",
                "& .MuiSlider-thumb": {
                  width: 16,
                  height: 16,
                },
              }}
            />
          </Box>

          {/* Text Width */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
              Text Width: {textSettings[`text${selectedTextIndex + 1}`].textAreaWidth}%
            </Typography>
            <Slider
              value={textSettings[`text${selectedTextIndex + 1}`].textAreaWidth}
              onChange={(_, value) => onTextSettingChange(`text${selectedTextIndex + 1}`, 'textAreaWidth', value)}
              min={20}
              max={100}
              sx={{
                color: "#1976d2",
                "& .MuiSlider-thumb": {
                  width: 16,
                  height: 16,
                },
              }}
            />
          </Box>
        </Box>
      )}

      {/* Global Settings */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          Global Settings
        </Typography>

        {/* Stroke Width */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
            Outline: {globalSettings.strokeWidth}%
          </Typography>
          <Slider
            value={globalSettings.strokeWidth}
            onChange={(_, value) => onGlobalSettingChange('strokeWidth', value)}
            min={0}
            max={50}
            sx={{
              color: "#1976d2",
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
              },
            }}
          />
        </Box>

        {/* Color Pickers */}
        <Box
          sx={{
            mb: 4,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 3,
          }}
        >
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
              Text Color
            </Typography>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "48px",
                borderRadius: 1,
                overflow: "hidden",
                border: "2px solid #e0e0e0",
              }}
            >
              <input
                type="color"
                value={globalSettings.textFillColor}
                onChange={(e) => onGlobalSettingChange('textFillColor', e.target.value)}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
              Outline Color
            </Typography>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "48px",
                borderRadius: 1,
                overflow: "hidden",
                border: "2px solid #e0e0e0",
              }}
            >
              <input
                type="color"
                value={globalSettings.textStrokeColor}
                onChange={(e) => onGlobalSettingChange('textStrokeColor', e.target.value)}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Font Selection */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 500 }}>
            Font
          </Typography>
          <FormControl fullWidth>
            <Select
              value={globalSettings.selectedFont}
              onChange={(e) => onGlobalSettingChange('selectedFont', e.target.value)}
              sx={{
                height: "48px",
                bgcolor: "#fff",
                "&:hover": {
                  bgcolor: "#fff",
                },
              }}
            >
              {fontOptions.map((font) => (
                <MenuItem
                  key={font.value}
                  value={font.value}
                  sx={{ fontFamily: font.value }}
                >
                  {font.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default MemeBrowseCanvas;
