import React, { forwardRef, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import generalApi from "../../api/generalApi";
import {
  Box,
  Container,
  Typography,
  IconButton,
  CircularProgress,
  Paper,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const BlogPostEditor = () => {
  const { blogPostId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  // Create a ref for ReactQuill
  const quillRef = useRef(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        setLoading(true);
        const response = await generalApi.project.getBlogPost(blogPostId);
        setTitle(response.results.title);
        setContent(response.results.content);
      } catch (error) {
        toast.error("Failed to load blog post");
        // navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
  }, [blogPostId, navigate]);

  // Add this useEffect to handle any initialization that needs the quill instance
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      // You can use the editor instance here if needed
    }
  }, []);

  const handleBack = () => {
    navigate("/");
  };

  const onContentChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await generalApi.project.updateBlogPost(blogPostId, {
        title,
        content,
      });
      toast.success("Blog post updated successfully");
      setIsEditing(false);
    } catch (error) {
      toast.error("Failed to update blog post");
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setIsEditing(true);
  };

  const handleMoreIdeas = () => {
    navigate("/blog-post-ideas");
  };

  return (
    <Box
      sx={{
        ml: { xs: 0, md: "70px" },
        height: { xs: "auto", md: "calc(100vh - 74px)" },
        overflow: { xs: "visible", md: "hidden" },
        bgcolor: "#f5f5f5",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: 4,
          //   px: { xs: 3, md: '15%' },
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <input
              value={title}
              onChange={handleTitleChange}
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                border: "none",
                background: "transparent",
                width: "100%",
                padding: "8px",
                outline: "none",
                borderBottom: isEditing
                  ? "2px solid #9c27b0"
                  : "2px solid transparent",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              onClick={handleSave}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                textTransform: "none",
              }}
            >
              Save Updates
            </Button>
            <Button
              variant="contained"
              onClick={handleMoreIdeas}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                textTransform: "none",
              }}
            >
              More Ideas
            </Button>
          </Box>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper
            elevation={0}
            sx={{
              flexGrow: 1,
              border: "1px solid rgba(0, 0, 0, 0.12)",
              mb: "10px",
              margin: "0 10%",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              "& .quill": {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                bgcolor: "white",
              },
              "& .ql-toolbar": {
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                padding: "12px 40px",
              },
              "& .ql-container": {
                flexGrow: 1,
                fontFamily:
                  '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                borderBottom: "none",
                borderLeft: "none",
                borderRight: "none",
                display: "flex",
                flexDirection: "column",
              },
              "& .ql-editor": {
                fontSize: "16px",
                lineHeight: "1.6",
                padding: "24px 60px",
                flexGrow: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#9c27b0",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "#7b1fa2",
                  },
                },
                "&::-webkit-scrollbar-track": {
                  background: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                },
                "& p": {
                  marginBottom: "1.5em",
                },
                "& h1": {
                  fontSize: "2.5em",
                  marginBottom: "1em",
                  color: "#1a1a1a",
                },
                "& h2": {
                  fontSize: "2em",
                  marginBottom: "0.8em",
                  color: "#1a1a1a",
                },
                "& h3": {
                  fontSize: "1.5em",
                  marginBottom: "0.6em",
                  color: "#1a1a1a",
                },
                "& h4": {
                  fontSize: "1.25em",
                  marginBottom: "0.6em",
                  color: "#1a1a1a",
                },
                "& ul, & ol": {
                  marginBottom: "1.5em",
                },
                "& blockquote": {
                  borderLeft: "4px solid #9c27b0",
                  paddingLeft: "1em",
                  marginLeft: "0",
                  marginBottom: "1.5em",
                  color: "#666",
                },
              },
            }}
          >
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={content}
              onChange={onContentChange}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image"],
                  ["clean"],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "link",
                "image",
              ]}
              preserveWhitespace={false}
            />
          </Paper>
        )}
      </Container>
    </Box>
  );
};

export default BlogPostEditor;
