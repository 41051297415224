import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import LanguageSelector from "../common/LanguageSelector";

const EditProjectModal = ({ open, onClose, project, onProjectUpdated }) => {
  const [projectData, setProjectData] = useState({
    _id: "",
    name: "",
    description: "",
    language: "",
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (open && project) {
      const existingLanguage =
        project.language || localStorage.getItem("selectedLanguage") || "en";

      setProjectData({
        _id: project._id,
        name: project.name,
        description: project.description || "",
        language: existingLanguage,
      });

      if (project.language) {
        localStorage.setItem("selectedLanguage", project.language);
      }
    }
  }, [open, project]);

  const handleChange = (field) => (event) => {
    setProjectData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
    if (error[field]) {
      setError((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};
    if (!projectData.name.trim()) {
      newErrors.name = "Project name is required";
    }
    if (!projectData.language) {
      newErrors.language = "Language is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    setLoading(true);
    try {
      await onProjectUpdated(projectData);
    } catch (err) {
      setError({
        name: err.message || "Failed to update project",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography variant="h6" component="div">
            Edit Project
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              autoFocus
              label="Project Name"
              fullWidth
              value={projectData.name}
              onChange={handleChange("name")}
              error={!!error.name}
              helperText={error.name}
              disabled={loading}
            />

            <TextField
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={projectData.description}
              onChange={handleChange("description")}
              error={!!error.description}
              helperText={error.description}
              disabled={loading}
            />

            <LanguageSelector
              value={projectData.language}
              onChange={handleChange("language")}
              error={!!error.language}
              helperText={error.language}
              disabled={loading}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Save Changes"
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProjectModal;
