import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Container,
  Paper,
  Checkbox,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import generalApi from "../../api/generalApi";
import LanguageSelector, { languages } from "../common/LanguageSelector";
import { LightbulbIcon, Wand2 } from "lucide-react";
import { Edit as EditIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, IconButton } from "@mui/material";
import { useLimits } from "../../context/LimitsContext";
import AddIcon from "@mui/icons-material/Add";
import AddProjectModal from "../Project/AddProjectModal";
import { Check as CheckIcon } from "@mui/icons-material";
import { Launch as LaunchIcon } from "@mui/icons-material";
import ReactCountryFlag from "react-country-flag";

const LANGUAGE_STORAGE_KEY = "lastSelectedLanguage";
const HIGHLIGHT_DURATION = 10000;
const PROJECT_STORAGE_KEY = "lastSelectedProject";

const getCountryCode = (languageCode) => {
  return languageCode.split("-")[1] || languageCode;
};

const BlogPostIdeas = () => {
  const [niche, setNiche] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [language, setLanguage] = useState(
    () => localStorage.getItem(LANGUAGE_STORAGE_KEY) || "en-US"
  );
  const [generatingIdeas, setGeneratingIdeas] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [newIdeasCount, setNewIdeasCount] = useState(0);
  const [hasGeneratedOnce, setHasGeneratedOnce] = useState(false);
  const [expandedCard, setExpandedCard] = useState(null);
  const [editableItems, setEditableItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [articleLength, setArticleLength] = useState(0);
  const { limits, fetchLimits } = useLimits();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [generatingStatuses, setGeneratingStatuses] = useState({});
  const [savedBlogPosts, setSavedBlogPosts] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const lengthOptions = [
    { value: 0, label: "Auto (Recommended)" },
    { value: 1, label: "Short" },
    { value: 2, label: "Medium" },
    { value: 3, label: "Long" },
  ];

  useEffect(() => {
    fetchProjects();
  }, [language]);

  const fetchProjects = async () => {
    try {
      setProjectsLoading(true);
      setError(null);
      const response = await generalApi.project.getProjectsLists();
      const filteredProjects = (response.results || []).filter(
        (project) => project.language === language
      );
      setProjects(filteredProjects);

      const savedProjectId = localStorage.getItem(PROJECT_STORAGE_KEY);
      if (savedProjectId) {
        const savedProject = filteredProjects.find(
          (p) => p._id === savedProjectId
        );
        if (savedProject) {
          setSelectedProject(savedProject);
        } else {
          localStorage.removeItem(PROJECT_STORAGE_KEY);
        }
      }
    } catch (err) {
      setError("Failed to load projects");
    } finally {
      setProjectsLoading(false);
    }
  };

  const handleLanguageChange = useCallback((e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setSelectedProject(null);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, newLanguage);
    localStorage.removeItem(PROJECT_STORAGE_KEY);
  }, []);

  const handleNicheChange = useCallback((e) => {
    setNiche(e.target.value);
  }, []);

  const handleInstructionsChange = useCallback((e) => {
    setAdditionalInstructions(e.target.value);
  }, []);

  const handleExpandClick = (index, forceOpen = false) => {
    if (forceOpen) {
      setExpandedCard(index);
    } else {
      setExpandedCard(expandedCard === index ? null : index);
    }
  };

  const handleEdit = (index, field, value) => {
    setEditableItems((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [field]: value,
      },
    }));
  };

  const handleSubmit = useCallback(
    async (e, isGeneratingMore = false) => {
      e?.preventDefault();
      if (!niche.trim()) {
        toast.error("Please enter a niche");
        return;
      }

      setGeneratingIdeas(true);
      try {
        const response = await generalApi.prompts.generateBlogTitles(
          language,
          niche,
          additionalInstructions
        );

        const newIdeas = response.results.titles.map(
          ([title, description]) => ({
            title,
            description: description || "",
          })
        );

        await fetchLimits();

        if (isGeneratingMore) {
          setNewIdeasCount(newIdeas.length);
          setIdeas((prevIdeas) => [...newIdeas, ...prevIdeas]);
          setTimeout(() => setNewIdeasCount(0), HIGHLIGHT_DURATION);
        } else {
          setIdeas(newIdeas);
          setHasGeneratedOnce(true);
        }
      } catch (error) {
        toast.error(error.message || "Failed to generate blog post ideas");
      } finally {
        setGeneratingIdeas(false);
      }
    },
    [niche, language, additionalInstructions, fetchLimits]
  );

  const handleGenerateMore = useCallback(
    (e) => handleSubmit(e, true),
    [handleSubmit]
  );

  const handleSelect = (index, e) => {
    e.stopPropagation();
    setSelectedItems((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  };

  const handleWriteArticle = async (index) => {
    try {
      setGeneratingStatuses((prev) => ({ ...prev, [index]: true }));
      const title = editableItems[index]?.title || ideas[index].title;

      // Start the 5-second timer for fetching limits as soon as the button is clicked
      setTimeout(async () => {
        await fetchLimits();
      }, 5000);

      const response = await generalApi.prompts.generateBlogPost(
        language,
        title,
        "", // additionalInstructions
        articleLength,
        selectedProject._id
      );

      // Clean up the HTML content
      // const htmlContent = response.results
      //   .replace(/\n\n/g, "\n")
      //   .replace(/\n/g, "")
      //   .replace(/<p><br><\/p>/g, "")
      //   .replace(/<p>\s*<\/p>/g, "")
      //   .replace(/>\s+</g, "><");

      // Save the blog post if a project is selected
      if (selectedProject?._id) {
        try {
          // const savedPost = await generalApi.project.addBlogPost({
          //   projectId: selectedProject._id,
          //   title: title,
          //   idea: editableItems[index]?.description || ideas[index].description,
          //   content: htmlContent,
          // });

          setSavedBlogPosts((prev) => ({
            ...prev,
            [index]: response.results._id,
          }));

          // Redirect to the new blog post editor route
          // window.open(
          //   `/social-first-blog-posts/${savedPost.results._id}`,
          //   "_blank"
          // );

          toast.success("Blog post saved successfully!");
        } catch (error) {
          toast.error("Failed to save blog post");
        }
      }
    } catch (error) {
      toast.error(error.message || "Failed to generate article");
    } finally {
      setGeneratingStatuses((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleProjectAdded = async (newProject) => {
    await fetchProjects();
    setIsAddModalOpen(false);
  };

  const handleRedirectToBlogPost = (blogPostId) => {
    window.open(`/social-first-blog-posts/${blogPostId}`, "_blank");
  };

  const renderStatusIcon = (index) => {
    // Show loading spinner while generating
    if (generatingStatuses[index]) {
      return (
        <CircularProgress size={20} sx={{ color: "#9c27b0", marginRight: 1 }} />
      );
    }

    // Show checkmark if post is saved
    if (savedBlogPosts[index]) {
      return (
        <CheckIcon
          sx={{
            color: "#4caf50",
            marginRight: 1,
            fontSize: 20,
          }}
        />
      );
    }

    // Show checkbox for selection
    return (
      <Checkbox
        checked={selectedItems.includes(index)}
        onChange={(e) => handleSelect(index, e)}
        onClick={(e) => e.stopPropagation()}
        sx={{
          color: "#9c27b0",
          "&.Mui-checked": { color: "#9c27b0" },
          padding: 0,
          marginRight: 1,
        }}
      />
    );
  };

  const renderActionButton = (index) => {
    if (savedBlogPosts[index] && selectedProject?._id) {
      return (
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            handleRedirectToBlogPost(savedBlogPosts[index]);
          }}
          startIcon={<LaunchIcon />}
          sx={{
            color: "#4caf50",
            borderColor: "#4caf50",
            "&:hover": {
              borderColor: "#45a049",
              backgroundColor: "rgba(76, 175, 80, 0.04)",
            },
            textTransform: "none",
            mt: 1,
          }}
        >
          View Saved Article
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          handleWriteArticle(index);
        }}
        disabled={generatingStatuses[index] || !selectedProject}
        sx={{
          bgcolor: "#9c27b0",
          "&:hover": { bgcolor: "#7b1fa2" },
          textTransform: "none",
          mt: 1,
        }}
      >
        {generatingStatuses[index] ? "Generating..." : "Write Article"}
      </Button>
    );
  };

  return (
    <Box
      sx={{
        ml: { xs: 0, md: "70px" },
        display: "flex",
        flexDirection: "column",
        minHeight: { xs: "auto", md: "calc(100vh - 74px)" },
        overflow: "hidden",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: 4,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography variant="h5" component="h1" sx={{ fontWeight: "bold" }}>
            Generate Blog Post Ideas
          </Typography>

          {hasGeneratedOnce && (
            <Tooltip title="2 Credits" arrow>
              <Button
                onClick={handleGenerateMore}
                disabled={generatingIdeas}
                variant="contained"
                sx={{
                  bgcolor: "#9c27b0",
                  "&:hover": { bgcolor: "#7b1fa2" },
                  textTransform: "none",
                }}
              >
                {generatingIdeas ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    Generating...
                  </Box>
                ) : (
                  <>
                    Generate More Ideas
                    <Wand2 size={18} style={{ marginLeft: 8 }} />
                  </>
                )}
              </Button>
            </Tooltip>
          )}
        </Box>

        <Grid
          container
          spacing={3}
          sx={{
            flexGrow: 1,
            height: { xs: "auto", md: "calc(100vh - 250px)" },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "100%",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                pt: 3,
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  p: 4,
                  pt: 0,
                  height: "100%",
                }}
              >
                <TextField
                  label="Niche"
                  value={niche}
                  onChange={handleNicheChange}
                  required
                  fullWidth
                  placeholder="e.g., Digital Marketing, Fitness, Cooking"
                  variant="outlined"
                />

                <LanguageSelector
                  value={language}
                  onChange={handleLanguageChange}
                />

                <>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 1,
                      color: "text.secondary",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={
                        languages.find((l) => l.code === language)?.countryCode
                      }
                      svg
                      style={{ width: "1em", height: "1em" }}
                    />
                    {projects.length > 0
                      ? `Showing Projects in ${
                          languages.find((l) => l.code === language)?.name ||
                          language
                        }`
                      : `No projects in ${
                          languages.find((l) => l.code === language)?.name ||
                          language
                        }, please create a project`}
                  </Typography>

                  <Autocomplete
                    options={[
                      ...projects,
                      {
                        _id: "add-new",
                        name: "Add New Project",
                        isAddOption: true,
                      },
                    ]}
                    getOptionLabel={(option) => option?.name || ""}
                    onChange={(_, value) => {
                      if (value?.isAddOption) {
                        setIsAddModalOpen(true);
                        setSelectedProject(null);
                        localStorage.removeItem(PROJECT_STORAGE_KEY);
                      } else {
                        setSelectedProject(value);
                        if (value?._id) {
                          localStorage.setItem(PROJECT_STORAGE_KEY, value._id);
                        } else {
                          localStorage.removeItem(PROJECT_STORAGE_KEY);
                        }
                      }
                    }}
                    value={selectedProject}
                    loading={projectsLoading}
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    required
                    noOptionsText={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          color: "text.secondary",
                        }}
                      >
                        No projects in{" "}
                        {languages.find((l) => l.code === language)?.name ||
                          language}
                        , please create a project
                      </Box>
                    }
                    renderOption={(props, option) => {
                      const { key, ...otherProps } = props;

                      if (option.isAddOption) {
                        return (
                          <Box
                            component="li"
                            key={option._id}
                            {...otherProps}
                            sx={{
                              "&.MuiAutocomplete-option": {
                                color: "#9c27b0",
                              },
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              if (option.isAddOption) {
                                setIsAddModalOpen(true);
                              }
                            }}
                          >
                            <AddIcon sx={{ mr: 1 }} />
                            {option.name}
                          </Box>
                        );
                      }

                      return (
                        <li key={option._id} {...otherProps}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <ReactCountryFlag
                              countryCode={
                                languages.find((l) => l.code === language)
                                  ?.countryCode
                              }
                              svg
                              style={{ width: "1em", height: "1em" }}
                            />
                            {option.name}
                          </Box>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Project"
                        placeholder="Search projects..."
                        error={!!error}
                        helperText={error}
                        required
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: selectedProject && (
                            <ReactCountryFlag
                              countryCode={
                                languages.find((l) => l.code === language)
                                  ?.countryCode
                              }
                              svg
                              style={{
                                width: "1em",
                                height: "1em",
                                marginLeft: "8px",
                                marginRight: "4px",
                              }}
                            />
                          ),
                          endAdornment: (
                            <>
                              {projectsLoading && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    minHeight: 0,
                  }}
                >
                  <TextField
                    label="Additional Instructions (Optional)"
                    value={additionalInstructions}
                    onChange={handleInstructionsChange}
                    multiline
                    minRows={2}
                    maxRows={100}
                    fullWidth
                    placeholder="Any specific requirements or focus areas"
                    variant="outlined"
                    sx={{
                      flex: 1,
                      "& .MuiInputBase-root": {
                        height: "100%",
                        alignItems: "flex-start",
                      },
                      "& .MuiOutlinedInput-input": {
                        height: "100% !important",
                      },
                    }}
                  />

                  <Tooltip title="2 Credits" arrow>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={generatingIdeas}
                      sx={{
                        bgcolor: "#9c27b0",
                        "&:hover": { bgcolor: "#7b1fa2" },
                        textTransform: "none",
                        padding: "8px 0",
                        width: "100%",
                        mt: 2,
                      }}
                    >
                      {generatingIdeas ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <>
                          Generate Ideas
                          <Wand2 size={18} style={{ marginLeft: 8 }} />
                        </>
                      )}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "100%",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                pt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  p: 4,
                  pt: 0,
                }}
              >
                {hasGeneratedOnce ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: "bold" }}
                      >
                        Generated Ideas
                      </Typography>

                      {selectedItems.length > 0 && (
                        <Tooltip title="Feature Coming This Week" arrow>
                          <span> {/* Wrapper needed for disabled button tooltip */}
                            <Button
                              variant="contained"
                              disabled={true} // Disabled until feature is ready
                              sx={{
                                bgcolor: "#9c27b0",
                                "&:hover": { bgcolor: "#7b1fa2" },
                                textTransform: "none",
                              }}
                            >
                              {/* Keep old code commented for future reference */}
                              {/* Save Selected ({selectedItems.length}) */}
                              Save Selected ({selectedItems.length})
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </Box>

                    <Box
                      sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        pr: 1,
                        mr: -1,
                        "&::-webkit-scrollbar": {
                          width: "8px",
                          background: "transparent",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#9c27b0",
                          borderRadius: "4px",
                          "&:hover": {
                            background: "#7b1fa2",
                          },
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "rgba(0, 0, 0, 0.1)",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      <Grid container spacing={2}>
                        {ideas.map((idea, index) => (
                          <Grid item xs={12} key={index}>
                            <Card
                              onClick={() => handleExpandClick(index, true)}
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: "none",
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                transition: "all 0.3s ease-in-out",
                                mt: index === 0 ? "5px" : 0,
                                cursor: "pointer",
                                "&:hover": {
                                  borderColor: "#9c27b0",
                                },
                                ...(index < newIdeasCount && {
                                  borderColor: "#9c27b0",
                                  bgcolor: "rgba(156, 39, 176, 0.05)",
                                }),
                              }}
                            >
                              <CardContent
                                sx={{
                                  p: "16px !important",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {renderStatusIcon(index)}
                                  <TextField
                                    fullWidth
                                    multiline
                                    variant="standard"
                                    value={
                                      editableItems[index]?.title || idea.title
                                    }
                                    onChange={(e) =>
                                      handleEdit(index, "title", e.target.value)
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleExpandClick(index, true);
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: {
                                        fontSize: "1rem",
                                        whiteSpace: "normal",
                                        wordBreak: "break-word",
                                      },
                                      readOnly: true,
                                      onFocus: (e) => {
                                        e.target.readOnly = false;
                                      },
                                      onBlur: (e) => {
                                        e.target.readOnly = true;
                                      },
                                    }}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        padding: 0,
                                        cursor: "pointer",
                                        "&:hover": {
                                          bgcolor: "rgba(0, 0, 0, 0.04)",
                                        },
                                      },
                                      "& .MuiInputBase-input": {
                                        whiteSpace: "normal",
                                        wordBreak: "break-word",
                                        lineHeight: "1.5",
                                        minHeight: "unset",
                                      },
                                    }}
                                  />
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleExpandClick(index);
                                    }}
                                    sx={{
                                      transform:
                                        expandedCard === index
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)",
                                      transition: "transform 0.3s",
                                      p: 0,
                                      ml: 1,
                                    }}
                                  >
                                    <ExpandMoreIcon />
                                  </IconButton>
                                </Box>

                                <Collapse in={expandedCard === index}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 2,
                                      mt: 1,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      multiline
                                      variant="standard"
                                      placeholder="Description"
                                      value={
                                        editableItems[index]?.description ||
                                        idea.description
                                      }
                                      onChange={(e) =>
                                        handleEdit(
                                          index,
                                          "description",
                                          e.target.value
                                        )
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: { fontSize: "0.9rem" },
                                        readOnly: !!savedBlogPosts[index],
                                      }}
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          padding: 0,
                                          "&:hover": {
                                            bgcolor: savedBlogPosts[index]
                                              ? "transparent"
                                              : "rgba(0, 0, 0, 0.04)",
                                          },
                                        },
                                      }}
                                    />

                                    {!savedBlogPosts[index] && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: 1,
                                          mt: 2,
                                          mb: 1,
                                        }}
                                      >
                                        {lengthOptions.map((option) => (
                                          <Button
                                            key={option.value}
                                            size="small"
                                            variant={
                                              articleLength === option.value
                                                ? "contained"
                                                : "outlined"
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setArticleLength(option.value);
                                            }}
                                            sx={{
                                              flex: 1,
                                              minWidth: 0,
                                              bgcolor:
                                                articleLength === option.value
                                                  ? "rgba(156, 39, 176, 0.08)"
                                                  : "transparent",
                                              borderColor:
                                                articleLength === option.value
                                                  ? "#9c27b0"
                                                  : "rgba(0, 0, 0, 0.12)",
                                              color: "#9c27b0",
                                              "&:hover": {
                                                bgcolor:
                                                  articleLength === option.value
                                                    ? "rgba(156, 39, 176, 0.12)"
                                                    : "rgba(156, 39, 176, 0.04)",
                                                borderColor: "#9c27b0",
                                              },
                                              textTransform: "none",
                                              py: 0.5,
                                              fontSize: "0.875rem",
                                            }}
                                          >
                                            {option.label}
                                          </Button>
                                        ))}
                                      </Box>
                                    )}

                                    {renderActionButton(index)}
                                  </Box>
                                </Collapse>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      color: "rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <LightbulbIcon size={48} />
                    <Typography
                      variant="h6"
                      sx={{ mt: 2, textAlign: "center" }}
                    >
                      Your generated blog post ideas will appear here
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <AddProjectModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onProjectAdded={handleProjectAdded}
        />
      </Container>
    </Box>
  );
};

export default BlogPostIdeas;
