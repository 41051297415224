import { useEffect } from 'react';

export const useResizeObserverFix = () => {
  useEffect(() => {
    const debounce = (fn, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => fn(...args), delay);
      };
    };

    const resizeObserverError = debounce((error) => {
      if (error.message.includes('ResizeObserver')) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.style.display = 'none';
        }
        error.preventDefault();
        error.stopPropagation();
      }
    }, 100);

    window.addEventListener('error', resizeObserverError);
    return () => window.removeEventListener('error', resizeObserverError);
  }, []);
}; 