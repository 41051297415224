import React, { useState } from "react";
import {
  Card,
  CardMedia,
  Typography,
  Button,
  Box,
  Skeleton,
} from "@mui/material";
import { Plus } from "lucide-react";
import MemeModal from "./MemeModal";

const MemeCard = ({ id, title, thumbnail, description, onUse, isTemplate = true }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmit = (formData) => {
    onUse(id, formData);
  };

  return (
    <>
      <Card
        sx={{
          position: "relative",
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "none",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            "& .overlay": {
              opacity: 1,
            },
          },
        }}
      >
        {!imageLoaded && (
          <Skeleton variant="rectangular" height={200} animation="wave" />
        )}
        <CardMedia
          component="img"
          height="200"
          image={thumbnail}
          alt={title}
          sx={{
            objectFit: "cover",
            display: imageLoaded ? "block" : "none",
          }}
          onLoad={() => setImageLoaded(true)}
        />
        <Box
          className="overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0, 0, 0, 0.7)",
            opacity: 0,
            transition: "opacity 0.3s",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "white",
              textShadow: "0 1px 2px rgba(0,0,0,0.2)",
            }}
          >
            {title}
          </Typography>
          <Button
            variant="contained"
            onClick={handleOpenModal}
            startIcon={<Plus size={16} />}
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
              textTransform: "none",
            }}
          >
            Use Template
          </Button>
        </Box>
      </Card>

      <MemeModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        title={title}
        isTemplate={isTemplate}
      />
    </>
  );
};

export default MemeCard;
