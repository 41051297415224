import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Link,
  Box,
  CircularProgress,
} from "@mui/material";
import authApi from "../api/authApi";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { useLimits } from "../context/LimitsContext";

function Login({ onLogin }) {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const { fetchLimits } = useLimits();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEmailFilled, setIsEmailFilled] = useState(false);
  const [isPasswordFilled, setIsPasswordFilled] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    const checkPrefilledFields = () => {
      if (emailRef.current) {
        const emailValue = emailRef.current.value;
        setEmail(emailValue);
        setIsEmailFilled(emailValue !== "");
      }
      if (passwordRef.current) {
        const passwordValue = passwordRef.current.value;
        setPassword(passwordValue);
        setIsPasswordFilled(passwordValue !== "");
      }
    };

    // Check immediately
    checkPrefilledFields();

    // Set up a MutationObserver to detect changes
    const observer = new MutationObserver(checkPrefilledFields);
    if (emailRef.current) {
      observer.observe(emailRef.current, {
        attributes: true,
        attributeFilter: ["value"],
      });
    }
    if (passwordRef.current) {
      observer.observe(passwordRef.current, {
        attributes: true,
        attributeFilter: ["value"],
      });
    }

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const data = await authApi.login(email, password);
      onLogin(data);
      await fetchLimits(true);
      navigate("/dashboard");
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        borderRadius: "0.5rem",
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
        color: "text.primary",
        boxShadow: "sm",
      }}
    >
      <CardContent>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setIsEmailFilled(e.target.value !== "");
            }}
            required
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: isEmailFilled,
            }}
            inputRef={emailRef}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setIsPasswordFilled(e.target.value !== "");
            }}
            required
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: isPasswordFilled,
            }}
            inputRef={passwordRef}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Login"}
          </Button>
        </Box>
        <Typography align="center" sx={{ mt: 2 }}>
          Don't have an account?{" "}
          <Link href="https://niche.sh" target="_blank">
            Sign Up
          </Link>
        </Typography>
        <Typography align="center" sx={{ mt: 1 }}>
          <Link component={RouterLink} to="/reset-password">
            Forgot password?
          </Link>
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Login;
