import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  Chip,
} from "@mui/material";
import {
  PlayCircleOutline as VideoIcon,
  LocalFireDepartment as FireIcon,
} from "@mui/icons-material";

const videos = [
  {
    title: "Full Guide",
    type: "basics",
    embedCode: `<iframe src="https://player.vimeo.com/video/1002768752?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="How To Use The Parasite SEO TOOL - ParasiteSEO"></iframe>`,
  },
  {
    title: "Main Dashboard",
    type: "basics",
    embedCode:
      '<div style="padding:53.13% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1002394376?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="ParasiteSEO.com Dashboard Overview"></iframe></div>',
  },
  {
    title: "Using Projects",
    type: "basics",
    embedCode:
      '<div style="padding:53.13% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1002397462?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Projects in Parasite SEO"></iframe></div>',
  },
  {
    title: "AI Overview",
    type: "basics",
    embedCode:
      '<div style="padding:53.13% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1002403090?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="AI Overview Parasite SEO"></iframe></div>',
  },
  {
    title: "Bulk Analyses",
    type: "basics",
    embedCode:
      '<div style="padding:53.13% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1002412529?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Bulk Analysis Parasite SEO"></iframe></div>',
  },
  {
    title: "How to make $100,000 a month with Parasite SEO",
    type: "tips",
    embedCode:
      '<div style="padding:75% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1008123795?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="How To Make $100k+ A MONTH w- Parasite SEO Partnerships - Jacky Chou"></iframe></div>',
  },
  {
    title: "Amazon Black Friday Parasite SEO hack",
    type: "tips",
    embedCode:
      '<div style="padding:62.5% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1008127822?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Amazon Black Friday Parasite SEO hack"></iframe></div>',
  },
  {
    title: "How to Find High Intent Keywords",
    type: "tips",
    embedCode:
      '<div style="padding:67.84% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1008139137?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="high intent keywords with parasite seo"></iframe></div>',
  },
  {
    title: "Fundamentals Module 1",
    type: "course",
    embedCode:
      '<div style="padding:57.94% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1002378475?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Parasite Fundamentals"></iframe></div>',
  },
];

function VideoScreen() {
  const [filter, setFilter] = useState(() => {
    const savedFilter = localStorage.getItem("selectedFilter");
    return savedFilter || "basics";
  });
  const [selectedVideo, setSelectedVideo] = useState(() => {
    return videos.find((v) => v.type === filter) || videos[0];
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredVideos = videos.filter((video) => video.type === filter);

  useEffect(() => {
    localStorage.setItem("selectedFilter", filter);
  }, [filter]);

  const handleChipClick = (newValue) => {
    setFilter(newValue);
    setSelectedVideo(videos.find((v) => v.type === newValue) || videos[0]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 2,
        p: 2,
        pt: "32px !important",
        height: "calc(100vh - 100px)",
        bgcolor: "#f5f5f5",
        overflow: "hidden",
      }}
    >
      {/* Left Panel - Video List */}
      <Paper
        elevation={0}
        sx={{
          width: isMobile ? "100%" : "320px",
          minWidth: isMobile ? "100%" : "320px",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "auto" : "100%",
          overflow: "hidden",
          bgcolor: "#ffffff",
          border: "1px solid",
          borderColor: "#e0e0e0",
        }}
      >
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography
            variant="h5"
            sx={{
              color: "#333",
              mb: 3,
              pt: 2,
              fontWeight: 600,
            }}
          >
            Video Training
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip
                label="App Basics"
                onClick={() => handleChipClick("basics")}
                sx={{
                  backgroundColor: filter === "basics" ? "#9333EA" : "#f0f0f0",
                  color: filter === "basics" ? "#fff" : "#333",
                  "&:hover": {
                    backgroundColor:
                      filter === "basics" ? "#7928d1" : "#e0e0e0",
                  },
                  fontSize: "0.875rem",
                  height: "32px",
                }}
              />
              <Chip
                label="Parasite Tips"
                onClick={() => handleChipClick("tips")}
                sx={{
                  backgroundColor: filter === "tips" ? "#9333EA" : "#f0f0f0",
                  color: filter === "tips" ? "#fff" : "#333",
                  "&:hover": {
                    backgroundColor: filter === "tips" ? "#7928d1" : "#e0e0e0",
                  },
                  fontSize: "0.875rem",
                  height: "32px",
                }}
              />
            </Box>
            <Chip
              icon={
                <FireIcon
                  sx={{ color: filter === "course" ? "#fff" : "#FF6B6B" }}
                />
              }
              label="Fundamentals Course"
              onClick={() => handleChipClick("course")}
              sx={{
                backgroundColor: filter === "course" ? "#9333EA" : "#f0f0f0",
                color: filter === "course" ? "#fff" : "#333",
                "&:hover": {
                  backgroundColor: filter === "course" ? "#7928d1" : "#e0e0e0",
                },
                fontSize: "0.875rem",
                height: "32px",
                alignSelf: "flex-start",
              }}
            />
          </Box>
        </Box>
        <List
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            maxHeight: isMobile ? "300px" : "calc(100vh - 250px)",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              backgroundColor: "#9333EA",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          {filteredVideos.map((video) => (
            <ListItem
              key={video.title}
              button
              onClick={() => setSelectedVideo(video)}
              selected={selectedVideo.title === video.title}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <ListItemIcon>
                <VideoIcon sx={{ color: "#666" }} />
              </ListItemIcon>
              <ListItemText
                primary={video.title}
                primaryTypographyProps={{ sx: { color: "#333" } }}
              />
            </ListItem>
          ))}
          {filter === "course" && (
            <ListItem>
              <ListItemText
                primary="More coming soon!"
                primaryTypographyProps={{
                  sx: { color: "#666", fontStyle: "italic" },
                }}
              />
            </ListItem>
          )}
        </List>
      </Paper>

      {/* Right Panel - Video Player */}
      <Card
        elevation={0}
        sx={{
          width: isMobile ? "100%" : "calc(100% - 340px)",
          minWidth: isMobile ? "100%" : "600px",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          bgcolor: "#ffffff",
          border: "1px solid",
          borderColor: "#e0e0e0",
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            p: 2,
            pt: 2,
            "&:last-child": { pb: 2 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#333",
              mb: 2,
              fontSize: "1.1rem",
              fontWeight: 600,
            }}
          >
            {selectedVideo.title}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 0,
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingTop: "56.25%",
                width: "100%",
                maxWidth: "1200px",
                maxHeight: "100%",
                overflow: "hidden",
                bgcolor: "background.paper",
                margin: "0 auto",
              }}
              dangerouslySetInnerHTML={{ __html: selectedVideo.embedCode }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default VideoScreen;
