import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  CircularProgress,
  Container,
  Paper,
  Checkbox,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import generalApi from "../../api/generalApi";
import LanguageSelector from "../common/LanguageSelector";
import { useLimits } from "../../context/LimitsContext";
import AddIcon from "@mui/icons-material/Add";
import AddProjectModal from "../Project/AddProjectModal";
import ReactCountryFlag from "react-country-flag";
import { languages } from "../common/LanguageSelector";
import { Wand2 } from "lucide-react";

const LANGUAGE_STORAGE_KEY = "lastSelectedLanguage";
const PROJECT_STORAGE_KEY = 'lastSelectedProject';
const HIGHLIGHT_DURATION = 10000;
const BACKGROUND_COLORS = [
  "#9c27b0", // Purple
  "#2196f3", // Blue
  "#4caf50", // Green
  "#ff9800", // Orange
  "#e91e63", // Pink
];

const StatusPostGenerator = () => {
  const [niche, setNiche] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [language, setLanguage] = useState(
    () => localStorage.getItem(LANGUAGE_STORAGE_KEY) || "en-US"
  );
  const [generatingPosts, setGeneratingPosts] = useState(false);
  const [posts, setPosts] = useState([]);
  const [hasGeneratedOnce, setHasGeneratedOnce] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { fetchLimits } = useLimits();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [error, setError] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [newPostsCount, setNewPostsCount] = useState(0);
  const [expandedPost, setExpandedPost] = useState(null);
  const [selectedColor, setSelectedColor] = useState(BACKGROUND_COLORS[0]);
  const [editableTexts, setEditableTexts] = useState({});

  useEffect(() => {
    fetchProjects();
  }, [language]);

  const fetchProjects = async () => {
    try {
      setProjectsLoading(true);
      setError(null);
      const response = await generalApi.project.getProjectsLists();
      const filteredProjects = (response.results || []).filter(
        (project) => project.language === language
      );
      setProjects(filteredProjects);
      
      const savedProjectId = localStorage.getItem(PROJECT_STORAGE_KEY);
      if (savedProjectId) {
        const savedProject = filteredProjects.find(p => p._id === savedProjectId);
        if (savedProject) {
          setSelectedProject(savedProject);
        } else {
          localStorage.removeItem(PROJECT_STORAGE_KEY);
        }
      }
    } catch (err) {
      setError("Failed to load projects");
    } finally {
      setProjectsLoading(false);
    }
  };

  const handleLanguageChange = useCallback((e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setSelectedProject(null);
    localStorage.setItem(LANGUAGE_STORAGE_KEY, newLanguage);
    localStorage.removeItem(PROJECT_STORAGE_KEY);
  }, []);

  const handleNicheChange = useCallback((e) => {
    setNiche(e.target.value);
  }, []);

  const handleInstructionsChange = useCallback((e) => {
    setAdditionalInstructions(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e?.preventDefault();
      if (!niche.trim()) {
        toast.error("Please enter a niche");
        return;
      }

      setGeneratingPosts(true);
      try {
        const response = await generalApi.prompts.generateStatusPosts(
          language,
          niche,
          additionalInstructions
        );

        await fetchLimits();
        setPosts(response.results.posts);
        setHasGeneratedOnce(true);
      } catch (error) {
        toast.error(error.message || "Failed to generate status posts");
      } finally {
        setGeneratingPosts(false);
      }
    },
    [niche, language, additionalInstructions, fetchLimits]
  );

  const handleSelect = (index) => {
    setSelectedItems((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  };

  const handleProjectAdded = async (newProject) => {
    await fetchProjects();
    setIsAddModalOpen(false);
  };

  const handleGenerateMore = useCallback(
    async (e) => {
      e?.preventDefault();
      if (!niche.trim()) {
        toast.error("Please enter a niche");
        return;
      }

      setGeneratingPosts(true);
      try {
        const response = await generalApi.prompts.generateStatusPosts(
          language,
          niche,
          additionalInstructions
        );

        await fetchLimits();
        const newPosts = response.results.posts;
        setNewPostsCount(newPosts.length);
        setPosts((prevPosts) => [...newPosts, ...prevPosts]);
        setTimeout(() => setNewPostsCount(0), HIGHLIGHT_DURATION);
      } catch (error) {
        toast.error(error.message || "Failed to generate status posts");
      } finally {
        setGeneratingPosts(false);
      }
    },
    [niche, language, additionalInstructions, fetchLimits]
  );

  const handlePostClick = (index) => {
    if (expandedPost === index) {
      setExpandedPost(null);
    } else {
      setExpandedPost(index);
      setSelectedColor(BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)]);
    }
  };

  const handleTextEdit = (index, newText) => {
    setEditableTexts(prev => ({
      ...prev,
      [index]: newText
    }));
  };

  return (
    <Box
      sx={{
        ml: { xs: 0, md: "70px" },
        display: 'flex',
        flexDirection: 'column',
        minHeight: { xs: 'auto', md: 'calc(100vh - 74px)' },
        overflow: 'hidden',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography variant="h5" component="h1" sx={{ fontWeight: "bold" }}>
            Generate Status Posts
          </Typography>

          {hasGeneratedOnce && (
            <Tooltip title="1 Credit" arrow>
              <Button
                onClick={handleGenerateMore}
                disabled={generatingPosts}
                variant="contained"
                sx={{
                  bgcolor: "#9c27b0",
                  "&:hover": { bgcolor: "#7b1fa2" },
                  textTransform: "none",
                }}
              >
                {generatingPosts ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    Generating...
                  </Box>
                ) : (
                  <>
                    Generate More Status Posts
                    <Wand2 size={18} style={{ marginLeft: 8 }} />
                  </>
                )}
              </Button>
            </Tooltip>
          )}
        </Box>

        <Grid
          container
          spacing={3}
          sx={{
            flexGrow: 1,
            height: { xs: 'auto', md: 'calc(100vh - 250px)' },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: '100%',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                pt: 3,
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  p: 4,
                  pt: 0,
                  height: '100%',
                }}
              >
                <TextField
                  label="Niche"
                  value={niche}
                  onChange={handleNicheChange}
                  required
                  fullWidth
                  placeholder="e.g., Digital Marketing, Fitness, Cooking"
                  variant="outlined"
                />

                <LanguageSelector
                  value={language}
                  onChange={handleLanguageChange}
                />

                <>
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      mb: 1, 
                      color: 'text.secondary',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={languages.find(l => l.code === language)?.countryCode}
                      svg
                      style={{ width: '1em', height: '1em' }}
                    />
                    {projects.length > 0 
                      ? `Showing Projects in ${languages.find(l => l.code === language)?.name || language}`
                      : `No projects in ${languages.find(l => l.code === language)?.name || language}, please create a project`
                    }
                  </Typography>

                  <Autocomplete
                    options={[
                      ...projects,
                      {
                        _id: "add-new",
                        name: "Add New Project",
                        isAddOption: true,
                      },
                    ]}
                    getOptionLabel={(option) => option?.name || ""}
                    onChange={(_, value) => {
                      if (value?.isAddOption) {
                        setIsAddModalOpen(true);
                        setSelectedProject(null);
                        localStorage.removeItem(PROJECT_STORAGE_KEY);
                      } else {
                        setSelectedProject(value);
                        if (value?._id) {
                          localStorage.setItem(PROJECT_STORAGE_KEY, value._id);
                        } else {
                          localStorage.removeItem(PROJECT_STORAGE_KEY);
                        }
                      }
                    }}
                    value={selectedProject}
                    loading={projectsLoading}
                    isOptionEqualToValue={(option, value) =>
                      option?._id === value?._id
                    }
                    required
                    renderOption={(props, option) => {
                      if (option.isAddOption) {
                        return (
                          <Box
                            component="li"
                            {...props}
                            sx={{
                              "&.MuiAutocomplete-option": {
                                color: "#9c27b0",
                              },
                            }}
                          >
                            <AddIcon sx={{ mr: 1 }} />
                            {option.name}
                          </Box>
                        );
                      }

                      return (
                        <li {...props}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <ReactCountryFlag
                              countryCode={languages.find(l => l.code === language)?.countryCode}
                              svg
                              style={{ width: '1em', height: '1em' }}
                            />
                            {option.name}
                          </Box>
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Project"
                        error={!!error}
                        helperText={error}
                        required
                      />
                    )}
                  />
                </>

                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  flex: 1,
                  minHeight: 0,
                }}>
                  <TextField
                    label="Additional Instructions (Optional)"
                    value={additionalInstructions}
                    onChange={handleInstructionsChange}
                    multiline
                    minRows={2}
                    maxRows={100}
                    fullWidth
                    placeholder="Any specific requirements or focus areas"
                    variant="outlined"
                    sx={{
                      flex: 1,
                      '& .MuiInputBase-root': {
                        height: '100%',
                        alignItems: 'flex-start'
                      },
                      '& .MuiOutlinedInput-input': {
                        height: '100% !important',
                      }
                    }}
                  />

                  <Tooltip title="1 Credit" arrow>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={generatingPosts}
                      sx={{
                        bgcolor: "#9c27b0",
                        "&:hover": { bgcolor: "#7b1fa2" },
                        textTransform: "none",
                        padding: "8px 0",
                        width: "100%",
                        mt: 2
                      }}
                    >
                      {generatingPosts ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <>
                          Generate Posts
                          <Wand2 size={18} style={{ marginLeft: 8 }} />
                        </>
                      )}
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: '100%',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                pt: 3,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  p: 4,
                  pt: 0,
                }}
              >
                {hasGeneratedOnce ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: "bold" }}
                      >
                        Generated Posts
                      </Typography>

                      {selectedItems.length > 0 && (
                        <Tooltip title="Feature Coming This Week" arrow>
                          <span>
                            <Button
                              variant="contained"
                              disabled={true}
                              sx={{
                                bgcolor: "#9c27b0",
                                "&:hover": { bgcolor: "#7b1fa2" },
                                textTransform: "none",
                              }}
                            >
                              Save Selected ({selectedItems.length})
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </Box>

                    <Box
                      sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        pr: 1,
                        mr: -1,
                      }}
                    >
                      <Grid container spacing={2}>
                        {posts.map((post, index) => (
                          <Grid item xs={12} key={index}>
                            <Card
                              onClick={() => handlePostClick(index)}
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                boxShadow: "none",
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                transition: "all 0.3s ease-in-out",
                                mt: index === 0 ? "5px" : 0,
                                cursor: "pointer",
                                "&:hover": {
                                  borderColor: "#9c27b0",
                                },
                                ...(index < newPostsCount && {
                                  borderColor: "#9c27b0",
                                  bgcolor: "rgba(156, 39, 176, 0.05)",
                                }),
                              }}
                            >
                              <CardContent
                                sx={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  gap: 2,
                                  p: "16px !important",
                                }}
                              >
                                <Checkbox
                                  checked={selectedItems.includes(index)}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleSelect(index);
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                  sx={{
                                    color: "#9c27b0",
                                    "&.Mui-checked": { color: "#9c27b0" },
                                    p: 0,
                                  }}
                                />
                                {expandedPost === index ? (
                                  <Box
                                    sx={{
                                      flex: 1,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 2,
                                    }}
                                  >
                                    <TextField
                                      fullWidth
                                      variant="standard"
                                      value={editableTexts[index] || post}
                                      onChange={(e) => {
                                        e.stopPropagation();
                                        handleTextEdit(index, e.target.value);
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                      InputProps={{
                                        disableUnderline: true,
                                        style: { 
                                          fontSize: "1rem",
                                          whiteSpace: 'normal',
                                          wordBreak: 'break-word'
                                        },
                                      }}
                                      sx={{
                                        "& .MuiInputBase-root": {
                                          padding: 0,
                                          cursor: "text",
                                          "&:hover": {
                                            bgcolor: "rgba(0, 0, 0, 0.04)",
                                          },
                                        },
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        bgcolor: selectedColor,
                                        borderRadius: 1,
                                        p: 2,
                                        minHeight: 200,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: 'white',
                                          textAlign: 'center',
                                          fontWeight: 'medium',
                                          wordBreak: 'break-word',
                                          display: 'block',
                                          width: '100%',
                                          wordWrap: 'break-word',
                                          overflowWrap: 'break-word',
                                          maxWidth: '34ch',
                                          margin: '0 auto',
                                          whiteSpace: 'pre-wrap',
                                        }}
                                      >
                                        {editableTexts[index] || post}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Typography 
                                    variant="body1"
                                    sx={{
                                      flex: 1,
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    {editableTexts[index] || post}
                                  </Typography>
                                )}
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      color: "rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <Typography variant="h6" sx={{ textAlign: "center" }}>
                      Your generated status posts will appear here
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <AddProjectModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
          onProjectAdded={handleProjectAdded}
        />
      </Container>
    </Box>
  );
};

export default StatusPostGenerator; 