import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Container,
  Grid,
} from "@mui/material";
import { Upload, Search } from "lucide-react";
import { useNavigate } from "react-router-dom";

const UploadCard = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "32px",
        }}
      >
        <Upload size={24} style={{ color: "#9c27b0", marginBottom: "16px" }} />
        <Typography variant="h6" gutterBottom>
          Upload Image
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 3, flexGrow: 1 }}
        >
          Upload your image and create a meme around it. Perfect for creating
          custom memes with your own images.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate("/meme-generator/upload")}
          fullWidth
          sx={{
            bgcolor: "#9c27b0",
            "&:hover": { bgcolor: "#7b1fa2" },
            textTransform: "none",
            padding: "12px 0",
            fontSize: "1rem",
          }}
        >
          Upload Image
        </Button>
      </CardContent>
    </Card>
  );
};

const BrowseCard = () => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        border: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "32px",
        }}
      >
        <Search size={24} style={{ color: "#9c27b0", marginBottom: "16px" }} />
        <Typography variant="h6" gutterBottom>
          Browse & Create
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mb: 3, flexGrow: 1 }}
        >
          Use our AI meme generator to create memes for your niche. Browse our
          collection of popular meme templates.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate("/meme-generator/browse")}
          sx={{
            bgcolor: "#9c27b0",
            "&:hover": { bgcolor: "#7b1fa2" },
            textTransform: "none",
            padding: "12px 0",
            fontSize: "1rem",
          }}
        >
          Browse Memes
        </Button>
      </CardContent>
    </Card>
  );
};

const MemeGenerator = () => {
  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography
        variant="h5"
        component="h1"
        sx={{
          mb: 4,
          fontWeight: "bold",
          textAlign: "left",
          pl: 2, // Added left padding to align with cards
        }}
      >
        Meme Generator
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={4}
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: "550px", margin: "0 auto" }}>
              <UploadCard />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: "550px", margin: "0 auto" }}>
              <BrowseCard />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default MemeGenerator;
